import Navbar from './components/Navbar';
import Layout from './components/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import Settings from './components/settings/settings';
import CreateTemplate from './components/Templates/CreateTemplate';
import Templates from './components/Templates/Templates';
import Customers from './components/Customers/Customers';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Broadcast from './components/broadcast/Broadcast';
import ScheduledBroadcast from './components/broadcast/ScheduledBroadcast';
import { useEffect } from 'react';
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LOGIN_USERTYPE_COOKIE, RIGHTS, EMP_USER_ID, REDIRECT_URL } from './Apis/config/ResponseHandler';
import Cookies from "js-cookie";
import { useState } from 'react';
import { requestFirebaseNotificationPermission, onMessageListener } from './firebaseConfig';
import socketIOClient from 'socket.io-client';
import { BASE_URL } from './Apis/config/Url';
import io from 'socket.io-client';
const ENDPOINT = BASE_URL;

export const socket = io(`${BASE_URL}`, {
  transports: ['websocket'], // Only use WebSocket
  pingInterval: 0, // Disable heartbeat interval
  pingTimeout: 0,  // Disable timeout for heartbeat
});

const NavigationHandler = ({ setDematData }) => {
  const navigate = useNavigate();
  useEffect(() => {

    // Get the current URL
    const currentUrl = window.location.href;

    // Create a URL object
    const url = new URL(currentUrl);
    // Get parameters from the URL
    const userTypeParam = url.searchParams.get('LOGIN_USERTYPE_COOKIE');
    const lac = url.searchParams.get('LOGIN_AUTH_COOKIE');
    const lrc = url.searchParams.get('LOGIN_REFRESH_COOKIE');
    const right = url.searchParams.get('r');
    const eui = url.searchParams.get('eui');
    const dematmobile = url.searchParams.get('mobile');
    const dematfirstname = url.searchParams.get('first_name');
    const dematlastname = url.searchParams.get('last_name');
    const dematcontact_id = url.searchParams.get('contact_id');
    const dematcontactupdated_at = url.searchParams.get('contact_updated_at')
    // setDematmobile(dematmobile)
    const dematData = {
      mobile: dematmobile,
      first_name: dematfirstname,
      last_name: dematlastname,
      contact_id: dematcontact_id,
      contact_updated_at: dematcontactupdated_at
    };
    setDematData(dematData);
    // Set cookies with the retrieved parameters
    if (userTypeParam) Cookies.set(LOGIN_USERTYPE_COOKIE, userTypeParam);
    if (lac) Cookies.set(LOGIN_AUTH_COOKIE, lac);
    if (lrc) Cookies.set(LOGIN_REFRESH_COOKIE, lrc);
    if (right) Cookies.set(RIGHTS, right);
    if (eui) Cookies.set(EMP_USER_ID, eui);

    // Conditional navigation
    const luca = Cookies.get(LOGIN_USERTYPE_COOKIE);
    if (luca === 'null' || luca === null || luca === undefined) {
      window.location.href = REDIRECT_URL;
    } else {
      // navigate('/', { state: { dematmobile } });
      //  navigate('/', { state: { dematmobile:dematmobile } });
      // navigate('/', { state: { dematData } });

    }
    // }, [navigate]);
  }, [navigate, setDematData]);

  return null;
};

function App() {
  const [dematData, setDematData] = useState(null);
  const [isTokenFound, setTokenFound] = useState(false);


  useEffect(() => {
    requestFirebaseNotificationPermission()
      .then(token => {
        if (token) {
          setTokenFound(true);
          // Send token to server to save it for sending notifications
          // fetch('http://localhost:3010/subscribe', {
          fetch(`${BASE_URL}subscribe`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          }).then(response => response.json())
            .then(data => {
              console.log('Token saved:', data);
            }).catch(error => {
              console.error('Error saving token:', error);
            });
        } else {
          setTokenFound(false);
        }
      })
      .catch(err => {
        console.log('An error occurred while requesting permission: ', err);
      });
  }, []);


  return (
    <Router>
      <Navbar />
      <NavigationHandler setDematData={setDematData} />
      <Routes>
        <Route path="/" element={<Layout dematData={dematData} />} />
        {/* <Route path="/" element={<Layout/>} /> */}
        <Route path="/settings" element={<Settings />} />
        {/* <Route path="/template" element={<CreateTemplate />} /> */}
        <Route path="/template" element={<Templates />} />
        <Route path="/broadcast" element={<Broadcast />} />
        <Route path="/create_template" element={<CreateTemplate />} />
        <Route path="/ScheduledBroadcast" element={<ScheduledBroadcast />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/home" />
      </Routes>
    </Router>

    // <>
    //   <Navbar />
    //   {/* <Layout /> */}
    //   {/* <Settings/> */}
    // {/* <CreateTemplate/> */}

    // </>

  )
}

export default App;
