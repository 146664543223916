import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Caller from './Apis/config/Caller';
import { sendToken_v1 } from './Apis/config/Url';
import { POST } from './Apis/config/RequestType';
import Cookies from "js-cookie";
import { EMP_USER_ID } from './Apis/config/ResponseHandler';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestFirebaseNotificationPermission = async () => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAP_ID_KEY });
        if (currentToken) {
            let user_id = Cookies.get(EMP_USER_ID);
            console.log('Notification permission granted. Token:', currentToken);
            const formData = new FormData();
            formData.append('device_token', currentToken);
            formData.append('created_by', user_id);

            await Caller(sendToken_v1, formData, POST, false, true).then((res) => {
                // console.log("res = ",res);
                if (res.success) {
                    // console.log('yeah token saved')
                }
            })

            // return currentToken;
        } else {
            console.log('No registration token available. Request permission to generate one.');
            // return null;
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
        return null;
    }
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
