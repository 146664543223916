
import React, { useState, useEffect, useRef } from 'react';
import { MdOutlineEmail, MdLocalPhone } from "react-icons/md";
import { IoMdSearch, IoMdClose } from "react-icons/io";
import { LuSend } from "react-icons/lu";
import { Button, Form } from 'react-bootstrap';
import { TbMailPlus } from "react-icons/tb";
import { VscLinkExternal } from "react-icons/vsc";
import { IoCalendarOutline } from "react-icons/io5";
import Caller from '../Apis/config/Caller';
import { POST, GET } from '../Apis/config/RequestType';
import { sendTemplate_v1, getTemplateFromTable_v1, BASE_URL, getAllParticipants_v1, sendBroadcastTemplate_v1, scheduleBroadcast_v1 } from '../Apis/config/Url';
import NewTemplatePopup from './NewTemplatePopup';
import { HashLoader } from 'react-spinners';
import { RiAlertFill } from "react-icons/ri";
import { FaTimes } from "react-icons/fa";
import { Dropdown } from 'react-bootstrap';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';


import { requestFirebaseNotificationPermission, onMessageListener } from '../firebaseConfig';
import socketIOClient from 'socket.io-client';
import io from 'socket.io-client';
import { socket } from '../App';

const ENDPOINT = BASE_URL;
// const socket = io(`${BASE_URL}`);

const TemplatePopup = ({ onClose, selectedContact, contactIndex, moveContact, tempRefresh, broadcast_id }) => {

    const [isSendTemplatePopupOpen, setSendTemplatePopupOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isNewTemplatePopupOpen, setNewTemplatePopupOpen] = useState(false);
    const [templateData, setTemplateData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [templatecount, setTemplateCount] = useState('');
    const [search, setSearch] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState('ALL');

    const [templatePage, setTemplatePage] = useState(1);
    const [totalTemplatePages, setTotalTemplatePages] = useState(null);
    const [teptTemplateData, setTempTemplateData] = useState([]);
    const [broadcastMobileNumbers, setBroadcastMobileNumbers] = useState('');
    const [isSendLaterPopupOpen, setSendLaterPopupOpen] = useState(false);
    const [selectedDateTime, setSelectedDateTime] = useState(new Date());
    const popupRef = useRef(null);
    const templateDivRef = useRef(null);
    const [textFields, setTextFields] = useState([]);
    const [isTokenFound, setTokenFound] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const templateStatus = [
        { value: 'ALL', label: 'All' },
        { value: 'APPROVED', label: 'Approved' },
        { value: 'IN REVIEW', label: 'Pending' },
        { value: 'REJECTED', label: 'Rejected' },
    ];

    useEffect(() => {
        const fetchTemplate = async () => {
            try {

                const getTemplate = await Caller(`${getTemplateFromTable_v1}?page=${Number(templatePage)}`, '', GET, false, false);
                setTemplateData(getTemplate.data.data);

                setTemplateCount(getTemplate.data.count)

                setTempTemplateData(getTemplate.data.data);
                setTemplatePage(getTemplate.data.page);
                setTotalTemplatePages(getTemplate.data.totalPages);
            } catch (error) {
                console.error('Error fetching template details:', error);
                // Handle error as needed
            }
        };

        fetchTemplate();
    }, []);

    const handleOverlayClick = (event) => {
        if (event.target.classList.contains('overlay')) {
            onClose();
        }
    };

    const handleSendTemplateOverlayClick = (event) => {
        if (event.target.classList.contains('send-template-overlay')) {
            setSendTemplatePopupOpen(false);
            setTextFields([]);

        }
    };

    const handleSendTemplatePop = async (template) => {
        setSelectedTemplate(template)
        setSendTemplatePopupOpen(true);
        const patternRegex = /{{\d+}}/g;
        let patternCount = [];
        patternCount = template.body.match(patternRegex);
        if (patternCount?.length ? patternCount?.length > 0 : patternCount != null) {
            setTextFields([]);
            for (let i = 1; i <= patternCount.length; i++) {
                setTextFields((variableCounter) => [...variableCounter, '']);
            }
        }
    }

    const generateUniqueID = () => {
        const randomNumber = Math.floor(10000 + Math.random() * 90000); // Generate a random 5-digit number
        return `BR${randomNumber}`;
    };

    useEffect(() => {
        const fetchParticipants = async () => {
            if (broadcast_id) {
                try {
                    const formData = new FormData();
                    const get_allParticipant = await Caller(`${getAllParticipants_v1}?selectedItemId=${broadcast_id}`, '', GET, false, false);

                    // Access the nested data array
                    const participants = get_allParticipant.data.data;
                    const mobileNumbersArray = participants.map(participant => participant.mobile);
                    const mobileNumbersString = mobileNumbersArray.join(',');

                    // Set the state with mobile numbers separated by commas
                    setBroadcastMobileNumbers(mobileNumbersString);
                } catch (error) {
                    console.error('Error fetching participants:', error);
                }
            }
        };

        fetchParticipants();
    }, [broadcast_id]);

    const handleSendTemplate = async () => {
        setLoading(true)
        if (broadcast_id) {
            const formData = new FormData();
            const uniqueID = generateUniqueID();


            formData.append("components_type", "BODY");
            formData.append("body_text", selectedTemplate.body || '');
            formData.append("language_code", "en_US");
            formData.append("template_name", selectedTemplate.template_name || '');
            formData.append("media_type", "");
            formData.append("media_link", "");
            formData.append("image_id", selectedTemplate.image_id || "");
            formData.append("variable_value", textFields || '');
            formData.append('group_id', broadcast_id)
            formData.append("mobile", broadcastMobileNumbers);
            formData.append('media_url_path', selectedTemplate.image_path || '')
            formData.append('template_history_id', uniqueID);


            await Caller(sendBroadcastTemplate_v1, formData, POST, false, true).then((res) => {

                if (res.success === true) {
                    onClose()
                }
                else {
                    enqueueSnackbar('Template message send failed.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
                    onClose()
                }
            })
            setSendTemplatePopupOpen(false);
            setLoading(false);

        } else {
            const formData = new FormData();
            formData.append("components_type", "BODY");
            formData.append("body_text", selectedTemplate.body || '');
            formData.append("language_code", "en_US");
            formData.append("template_name", selectedTemplate.template_name || '');
            formData.append("media_type", "");
            formData.append("media_link", "");
            formData.append("image_id", selectedTemplate.image_id || "");
            formData.append("variable_value", textFields || '');
            formData.append("mobile", selectedContact.mobile);

            await Caller(sendTemplate_v1, formData, POST, false, true).then((res) => {
                if (res.success === true) {
                    onClose();
                }
                else {
                    enqueueSnackbar('Template message send failed.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
                    onClose();
                }
            })
            setSendTemplatePopupOpen(false);
            setLoading(false);

            //Move contact after sending template
            moveContact(contactIndex, 0);
            onClose();
        }

    }

    const handleNewTemplateClicked = () => {
        setNewTemplatePopupOpen(true);
    }

    const handleSearch = () => {
        setSearch(true)
    }

    const handleClearSearch = () => {
        setSearchQuery('');
        setSearch(false);
        handleTemplateStatus({ target: { value: selectedStatus } });
    };

    const closePopup = () => {
        setNewTemplatePopupOpen(false);
    };

    const handleStatusChange = (event) => {
        const value = event.target.value;
        setSelectedStatus(value);
    };

    const handleTemplateScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollBottom = scrollHeight - clientHeight - scrollTop;

        if (Math.floor(scrollBottom) <= 0 && (templatePage < totalTemplatePages)) {
            setTemplatePage(Number(templatePage + 1))

            //api call
            const getTemplate = await Caller(`${getTemplateFromTable_v1}?page=${Number(templatePage + 1)}&s=${searchQuery}`, '', GET, false, false);
            setTempTemplateData(prevTemp => [...teptTemplateData, ...getTemplate.data.data]);
        }

    }

    const handleTemplateStatus = async (e) => {
        setTempTemplateData([]);
        setSearchQuery('')
        setSelectedStatus(e.target.value)
        const params = new URLSearchParams();

        if (e.target.value === 'ALL') {
            e.target.value = ''
        }
        const getTemplate = await Caller(`${getTemplateFromTable_v1}?status=${e.target.value}`, '', GET, false, false);
        setTemplateData(getTemplate.data.data);
        setTemplateCount(getTemplate.data.count)
        setTempTemplateData(getTemplate.data.data);
        // setTemplatePage(getTemplate.data.page);
        setTotalTemplatePages(getTemplate.data.totalPages);
    };

    const handleTemplateSearch = async (e) => {
        setSearchQuery(e.target.value)
        const params = new URLSearchParams();
        try {
            if (e.target.value) {
                params.append('s', e.target.value);
            }
            if (selectedStatus !== 'ALL') {
                params.append('status', selectedStatus);
            }
            if (e.target.value || selectedStatus) {
                const getTemplate = await Caller(`${getTemplateFromTable_v1}?${params.toString()}`, '', GET, false, false);
                setTemplateData(getTemplate.data.data);
                setTemplateCount(getTemplate.data.count)

                setTempTemplateData(getTemplate.data.data);
            }
        } catch (error) {
            console.error('Error fetching template details:', error);
            // Handle error as needed
        }
    };

    const handleAddTemplate = (newTemplate) => {
        setTempTemplateData((prevTemplateData) => [newTemplate, ...prevTemplateData]);
        setTemplateCount(templatecount + 1)
    };
    //--------send Later-----------//

    const handleSendLater = () => {
        // Set the current date and time
        const now = moment().format('YYYY-MM-DDTHH:mm');
        setSelectedDateTime(now);
        setSendLaterPopupOpen(true);
        setErrorMessage('')
    };

    const handleConfirmSendLater = async () => {
        setLoading(true);
        if (broadcast_id) {
            const formData = new FormData();
            const uniqueID = generateUniqueID();
            formData.append("description", selectedTemplate.body || '');
            formData.append("language_code", "en_US");
            formData.append("template_name", selectedTemplate.template_name || '');
            formData.append('template_id', selectedTemplate.id)
            formData.append("image_id", selectedTemplate.image_id || "");
            formData.append('date_time', selectedDateTime)
            formData.append("mobile", broadcastMobileNumbers);
            formData.append('whatsapp_group_id', broadcast_id)
            formData.append("body_text", selectedTemplate.body || '');
            formData.append('template_history_id', uniqueID);
            formData.append("variable_value", textFields || '');
            await Caller(scheduleBroadcast_v1, formData, POST, false, true).then((res) => {
                setLoading(false);
                setSendLaterPopupOpen(false);
                // enqueueSnackbar('Template scheduled successfully.', { variant: 'success', autoHideDuration: 2000, style: { zIndex: '99999' } });
                // onClose()
                // enqueueSnackbar('Template scheduled successfully.', {
                //     variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' },style: {zIndex: '9999' }, onClose: (event, reason) => {
                //         if (reason === 'timeout') {
                //             onClose();

                //         }
                //     }
                // });
                navigate('/ScheduledBroadcast')

            })
        }

    };

    const handleCancelSendLater = () => {
        setSendLaterPopupOpen(false);
        setErrorMessage('')
    };


    const handleDateChange = (e) => {
        const newDateTime = e.target.value;
        setSelectedDateTime(newDateTime);

        // Check if the selected date is before the current date and time
        if (new Date(newDateTime) < new Date()) {
            setErrorMessage('Selected date and time must be in the future.');
        } else if (newDateTime == '') {
            setErrorMessage('Invalid Date Time! Please select a valid date time.');
        } else {
            setErrorMessage('');
        }
    };

    const handleTextFieldChange = (index) => (event) => {
        const valueWithoutSpaces = event.target.value.replace(/\s+/g, ''); // Remove all spaces
        const newTextFields = [...textFields];
        newTextFields[index] = valueWithoutSpaces;
        setTextFields(newTextFields);
    };

    const captureData = async (data) => {

        if (data.data.type == "message_template_status_update") {

            let updateData = JSON.parse(data?.data?.new_message);
            const message_template_id = updateData.message_template_id;
            let status = updateData.status;

            const updatedData = teptTemplateData.map(item => {
                if (item.template_id === Number(message_template_id)) {
                    return { ...item, status: status };

                }
                return item;
            });

            setTempTemplateData(updatedData);
        }

    }

    onMessageListener().then(payload => {
        captureData(payload);
    }).catch(err => console.log('failed: ', err));


    const isDisabled = () => {
        if (textFields.some(item => item === '')) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <SnackbarProvider style={{ marginTop: '15%' }} />
            <div
                className="overlay"
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,

                }}
                onMouseDown={handleOverlayClick}
            >
                <div
                    style={{
                        width: '50%',
                        height: '90vh',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '20px',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        overflowY: 'auto',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                    }}
                >{!isNewTemplatePopupOpen ? (
                    <>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >

                            {search && (
                                <div style={{ position: 'absolute', width: '95%', height: '30px', color: '#c2c2c2' }}>
                                    <input
                                        type="text"
                                        placeholder="Enter Search term"
                                        value={searchQuery}
                                        onChange={handleTemplateSearch}
                                        style={{ width: '100%', height: '30px', border: 'none', outline: 'none', boxShadow: 'none' }}

                                    />
                                    <FaTimes
                                        style={{ color: '#3F906D', cursor: 'pointer', position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)' }}
                                        onClick={handleClearSearch}
                                    />
                                </div>
                            )}



                            {!search && (<h5 style={{ marginRight: 'auto', fontWeight: 'bold' }}>Templates</h5>)}
                            {!search && (<IoMdSearch style={{ width: '60px', height: '30px', cursor: 'pointer', color: '#278f55' }} onClick={handleSearch} />)}
                            {!search && Cookies.get('auth_usertype') != '5' && (<button style={{
                                backgroundColor: '#278f55',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '5px',
                                padding: '10px 20px'
                            }} onClick={handleNewTemplateClicked}>
                                <TbMailPlus style={{ width: '50px', height: '25px' }} />
                                New Template
                            </button>)}
                        </div>
                        <div style={{ marginTop: '20px', width: '100%' }}>
                            <hr style={{ border: '1px solid black', width: '100%', margin: '8px 0' }} />
                            <label htmlFor="templateStatus" style={{ display: 'block', color: '#6e6666' }}>Template status</label>

                            <div style={{ display: 'flex', gap: '20px', marginTop: '25px' }}>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdown-basic"
                                        // value={selectedStatus}
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: '1px solid #3F906D',
                                            color: 'black',
                                            fontWeight: '500',
                                            fontSize: '12px'
                                        }}
                                    >
                                        {/* {selectedStatus === 'IN REVIEW' ? "Pending" : selectedStatus === 'APPROVED' ? 'Approved' : selectedStatus === 'ALL' ? 'All' : selectedStatus === 'REJECTED' ? 'Rejected' : ''} */}
                                        {selectedStatus === '' ? 'Select Status' : templateStatus.find(status => status.value === selectedStatus)?.label}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ border: 'none', width: '250px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)' }}>

                                        {templateStatus.map(status => (
                                            <Dropdown.Item key={status.value} onClick={() => handleTemplateStatus({ target: { value: status.value } })}>
                                                {status.label}
                                            </Dropdown.Item>
                                        ))}


                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        </div>
                        <hr style={{ border: '1px solid black', width: '100%', margin: '10px 0' }} />

                        <div style={{ flex: 1, width: '100%', overflowY: 'auto', scrollbarWidth: 'thin' }} ref={templateDivRef} onScroll={handleTemplateScroll}>
                            {templatecount === 0 &&
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <RiAlertFill style={{ fontSize: '30px', color: '#6e6666' }} />
                                    <p style={{ fontWeight: '400', color: '#6e6666' }}>No template found</p>
                                </div>}
                            {teptTemplateData?.map((template, index) => (
                                <div key={index} style={{ width: '100%' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ marginRight: '20px', fontWeight: 'bold', color: '#a19f9f' }}>{template.template_name}</div>
                                        <span style={{ color: '#a19f9f' }}>•</span>
                                        <div style={{ marginRight: '20px', fontWeight: 'bold', color: '#a19f9f' }}>{template.status === "IN REVIEW" ? "PENDING" : template.status}</div>
                                        <span style={{ color: '#a19f9f' }}>•</span>
                                        <div style={{ fontWeight: 'bold', color: '#a19f9f' }}>{`Created By ${template.user_details}`}</div>
                                    </div>

                                    <div style={{ padding: '10px', width: '100%' }}>
                                        <div style={{ padding: '10px', width: '50%', display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ border: '3px solid #E5E4E2', borderRadius: '10px', minWidth: '90%', maxWidth: '90%' }}>
                                                <div>

                                                    {template.image_path && <img src={template.image_path} alt="Description of image" style={{
                                                        width: '100%', height: '80%', padding: 0, borderTopLeftRadius: '7px',
                                                        borderTopRightRadius: '7px'
                                                    }} />}

                                                </div>
                                                <div style={{ padding: '5px', wordWrap: 'break-word' }}>
                                                    {template.body ? template.body : ""}
                                                </div>

                                                <div style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    display: "flex",
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    {(template.url_btn_name) && <div style={{
                                                        width: '100%',
                                                        padding: 2,
                                                        justifyContent: 'center',
                                                        // fontWeight: 'bold',
                                                        display: 'flex',
                                                        paddingBottom: '5px',
                                                        color: '#00BFFF',
                                                        borderTop: '1px solid #ccc',
                                                        alignItems: 'center'
                                                    }}>
                                                        <a href={`https://${template.url_link}`} target='_blank' style={{
                                                            width: '100%',
                                                            textDecoration: 'none',
                                                            padding: 2,
                                                            justifyContent: 'center',
                                                            // fontWeight: 'bold',
                                                            display: 'flex',
                                                            paddingBottom: '5px',
                                                            color: '#00BFFF',
                                                            alignItems: 'center'
                                                        }}> <VscLinkExternal style={{ marginRight: '5px' }} />{template.url_btn_name} </a>
                                                    </div>}
                                                    {(template.btn_name) && <div style={{
                                                        width: '100%',
                                                        padding: 2,
                                                        justifyContent: 'center',
                                                        // fontWeight: 'bold',
                                                        padding: '5px',
                                                        display: 'flex',
                                                        marginBottom: '10px',
                                                        borderTop: '1px solid #ccc',
                                                        color: '#00BFFF',
                                                        alignItems: 'center'
                                                    }}>
                                                        <MdLocalPhone style={{ marginRight: '5px' }} />{template.btn_name}
                                                    </div>}
                                                </div>
                                            </div>
                                            {template.status == 'APPROVED' && <div style={{ padding: '10px', width: '50%', height: '100%' }}>
                                                <Button variant="success" style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: 'white',
                                                    borderRadius: '5px'
                                                }} onClick={() => handleSendTemplatePop(template)}>
                                                    <LuSend style={{ marginRight: '5px', color: 'white', width: '30px', height: '20px' }} />
                                                </Button>
                                            </div>}
                                        </div>
                                    </div>
                                    {index < templateData.length - 1 && <hr style={{ border: '1px solid black', width: '100%', margin: '10px 0' }} />}
                                </div>
                            ))}
                        </div>
                    </>) : (
                    <NewTemplatePopup onClose={closePopup} onAddTemplate={handleAddTemplate} />
                )}

                </div>
            </div>

            {isSendTemplatePopupOpen && (
                <div className="send-template-overlay" style={{
                    position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', overflowY: 'auto', justifyContent: 'center', alignItems: 'center', zIndex: 9999
                }} onClick={handleSendTemplateOverlayClick}>
                    <div style={{
                        backgroundColor: '#fbfbfc', width: '490px', height: '600px', display: 'flex', flexDirection: 'column', borderRadius: '10px', position: 'relative'
                    }}>
                        <div style={{
                            padding: '10px', borderBottom: '1px solid black', display: 'flex',
                            justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#fff',
                            borderTopLeftRadius: '10px', borderTopRightRadius: '10px'
                        }}>
                            <h6>Send Template</h6>
                            <IoMdClose style={{ cursor: 'pointer' }} onClick={() => { setSendTemplatePopupOpen(false); setTextFields([]) }} />
                        </div>

                        {loading && <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 20,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <HashLoader color="#176433" size={100} />
                        </div>}

                        <div style={{ flex: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column', msOverflowStyle: 'none', scrollbarWidth: 'none', marginBottom: '50px' }}>
                            <div style={{
                                display: 'flex', flexDirection: 'column',
                                marginTop: '10px', marginLeft: '10px'
                            }}>
                                <div style={{
                                    border: '1px solid #ccc', width: '300px',
                                    height: 'auto', maxHeight: 'auto', borderRadius: '10px', backgroundColor: 'white', overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none'
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {`${selectedTemplate?.image_path}` && <img src={`${selectedTemplate?.image_path}`} alt="Description of image" style={{
                                            width: '100%', height: 'auto', padding: 0, borderTopLeftRadius: '7px',
                                            borderTopRightRadius: '7px'
                                        }} />}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', width: '100%', wordWrap: 'break-word' }}>
                                        <p style={{ margin: 0 }}> {selectedTemplate.body ? selectedTemplate.body : ""}</p>
                                    </div>

                                    <div style={{
                                        margin: 0,
                                        padding: 0,
                                        display: "flex",
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}>
                                        {(selectedTemplate.url_btn_name) && <div style={{
                                            width: '100%',
                                            padding: 2,
                                            justifyContent: 'center',
                                            // fontWeight: 'bold',
                                            display: 'flex',
                                            paddingBottom: '5px',
                                            color: '#00BFFF',
                                            borderTop: '1px solid #ccc',
                                            alignItems: 'center'
                                        }}>
                                            <a href={`https://${selectedTemplate.url_link}`} target='_blank' style={{
                                                width: '100%',
                                                textDecoration: 'none',
                                                padding: 2,
                                                justifyContent: 'center',
                                                // fontWeight: 'bold',
                                                display: 'flex',
                                                paddingBottom: '5px',
                                                color: '#00BFFF',
                                                alignItems: 'center'
                                            }}> <VscLinkExternal style={{ marginRight: '5px' }} />{selectedTemplate.url_btn_name} </a>

                                        </div>}
                                        {(selectedTemplate.btn_name) && <div style={{
                                            width: '100%',
                                            padding: 2,
                                            justifyContent: 'center',
                                            // fontWeight: 'bold',
                                            padding: '5px',
                                            display: 'flex',
                                            marginBottom: '10px',
                                            borderTop: '1px solid #ccc',
                                            color: '#00BFFF',
                                            alignItems: 'center'
                                        }}>
                                            <MdLocalPhone style={{ marginRight: '5px' }} />{selectedTemplate.btn_name}
                                        </div>}
                                    </div>
                                </div>

                                <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', marginTop: '10px' }}>
                                    {textFields && textFields.map((value, index) => (
                                        <Form.Group className="mb-3" controlId="typeMessageBody" key={index}>
                                            <Form.Control type="text" className="form-control-lg" style={{
                                                fontSize: '16px', boxShadow: 'none', width: '195px', margin: '5px'
                                            }}
                                                value={value}
                                                onChange={handleTextFieldChange(index)}
                                                placeholder={`Variable ${index + 1}`}
                                                required
                                            />
                                        </Form.Group>
                                    ))}
                                </div>
                            </div>

                            <div style={{
                                display: 'flex', justifyContent: 'flex-end', padding: '10px', borderTop: '1px solid black', backgroundColor: '#fff', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px',
                                position: 'absolute', bottom: 0, left: 0, right: 0,
                            }}> {broadcast_id && <Button variant="success" onClick={handleSendLater} disabled={isDisabled()} style={{ display: 'flex', alignItems: 'center', color: 'white', borderRadius: '5px', backgroundColor: '#414175', marginRight: '10px' }} >
                                <IoCalendarOutline style={{ marginRight: '5px', color: 'white' }} />
                                Send Later
                            </Button>}
                                <Button variant="success" style={{ display: 'flex', alignItems: 'center', color: 'white', borderRadius: '5px' }} onClick={handleSendTemplate} disabled={isDisabled()}>
                                    <LuSend style={{ marginRight: '5px', color: 'white' }} />
                                    Send Template
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {isSendLaterPopupOpen && (

                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '8px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                        zIndex: '99999',
                        width: '350px',
                        height: '300px',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                    ref={popupRef}
                >
                    <div>
                        <h4>Select Date and Time</h4>
                        <div style={{ marginBottom: '10px', width: '100%' }}>
                            <div style={{ display: 'inline-block', width: '100%' }}>
                                <input
                                    type="datetime-local"
                                    value={selectedDateTime}
                                    onChange={handleDateChange}
                                    style={{ padding: '8px', marginBottom: '8px', width: '100%', border: '1px solid #ccc', boxSizing: 'border-box', borderRadius: '4px', outline: 'none', boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)' }}
                                />
                            </div>
                        </div>
                        {errorMessage && (
                            <p style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</p>
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <button
                            onClick={handleCancelSendLater}
                            style={{ padding: '6px 12px', backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleConfirmSendLater}
                            disabled={errorMessage}
                            style={{ padding: '6px 12px', backgroundColor: errorMessage ? 'rgb(200, 200, 200)' : '#198754', color: 'white', border: 'none', borderRadius: '4px', cursor: errorMessage ? 'default' : 'pointer' }}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            )}


        </>
    );
};

export default TemplatePopup;

