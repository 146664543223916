
import React, { useState, useRef } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormControl, Form, Button, Dropdown } from 'react-bootstrap';
import { IoSearchOutline } from "react-icons/io5";
// import MiniSidebar from '../MiniSidebar';
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import Badge from 'react-bootstrap/Badge';
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { FaCircle } from "react-icons/fa";
import backgroundImage from '../images/whatsappImage.jpeg';


import { MdLocalPhone } from "react-icons/md";
import { VscLinkExternal } from "react-icons/vsc";
import { LuLaugh } from "react-icons/lu";
import { GoPlus } from "react-icons/go";
import { MdMic } from "react-icons/md";
import { IoInformationCircleOutline } from "react-icons/io5";
import { HiOutlineArrowsUpDown } from "react-icons/hi2";
import { PiDotsSixVerticalBold } from "react-icons/pi";
import { MdCancel } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTemplate_v1, getUploadSessionId_v1, getHandlerId_v1, uploadFile, BASE_URL, uploadFile_v1 } from '../Apis/config/Url';
import Caller from '../Apis/config/Caller';
import { POST } from '../Apis/config/RequestType';
import { ClipLoader, HashLoader, PuffLoader } from 'react-spinners';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';


const NewTemplatePopup = ({ onClose, onAddTemplate }) => {

    const [templateStatus, setTemplateStatus] = useState("All");
    const [activeTabButton, setActiveTabButton] = useState(1);
    const [showPhoneFields, setShowPhoneFields] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [typeMessageBody, setTypeMessageBody] = useState('');
    const [messageFooter, setMessageFooter] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [charCountBody, setCharCountBody] = useState(0);
    const [charCountFooter, setCharCountFooter] = useState(0);
    const [showWebsiteFields, setShowWebsiteFields] = useState(false);
    const [websiteFields, setWebsiteFields] = useState([]);
    const [charCountWebsite, setCharCountWebsite] = useState(0);
    const [charCountPhone, setCharCountPhone] = useState(0);
    const [buttonWebsite, setButtonWebsite] = useState('');
    const [buttonPhone, setButtonPhone] = useState('');
    const [charCountWebsiteUrl, setCharCountWebsiteUrl] = useState(0);
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [charCountPhoneNumber, setCharCountPhoneNumber] = useState(0);
    const [phoneNumber, setphonenumber] = useState('');
    const [websitebuttonName, setWebsiteButtonName] = useState('');
    const [phoneButtonName, setPhoneButtonName] = useState('');
    const fileInputRef = useRef(null);
    const [typeMessageImage, setTypeMessageImage] = useState('');
    const [selectedImage, setSelectedImage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedImagePreview, setSelectedImagePreview] = useState('')
    const [selectedOption, setSelectedOption] = useState('None');
    const [templateImagePath, setTemplateImagePath] = useState(null)
    const [loading, setLoading] = useState(false);
    const [isUrlValid, setIsUrlValid] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [height, setHeight] = useState('1px');

    const [textFields, setTextFields] = useState([]);
    const [compareArray, setCompareArray] = useState([]);
    const [patternCounter, setPatternCounter] = useState(0);
    const [variableCounter, setVariableCounter] = useState(0);
    const [disabledVariable, setDisabledvariable] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState('Marketing');
    const [selectedTemplateType, setSelectedTemplateType] = useState('Custom');
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('English');
    const [mediaHandlerId, setMediaHandlerId] = useState("");
    const [imageId, setImageId] = useState("");
    const [imageName, setImageName] = useState("");

    const handleWebsiteClick = () => {
        setShowWebsiteFields(true);
        // if (showWebsiteFields.length < 2) {
        //     setShowWebsiteFields([...showWebsiteFields, {}]);
        // }
    };

    // const handleWebsiteClick = () => {
    //     if (websiteFields.length < 2) {
    //         setWebsiteFields([...websiteFields, {}]);

    //     }
    // };
    const handlePhoneClick = () => {
        setShowPhoneFields(true);
    };
    const handleCloseWebsite = () => {
        setShowWebsiteFields(false);
        setWebsiteButtonName('');
        setButtonWebsite('');
        setCharCountWebsite(0)
        setCharCountWebsiteUrl(0)
        setWebsiteUrl('')
    };
    // const handleCloseWebsite = (index) => {
    //     const newWebsiteFields = showWebsiteFields.filter((_, i) => i !== index);
    //     setShowWebsiteFields(newWebsiteFields);
    // };
    // const handleCloseWebsite = (index) => {
    //     const newWebsiteFields = websiteFields.filter((_, i) => i !== index);
    //     setWebsiteFields(newWebsiteFields);
    // };

    const handleClosePhone = () => {
        setShowPhoneFields(false);
        setPhoneButtonName('');
        setButtonPhone('');
        setCharCountPhone(0);
        setCharCountPhoneNumber(0)
        setphonenumber('')
    };
    const handleAcitveTabBasicInfo = () => {
        setActiveTabButton(1)
    }

    const handleAcitveTabContent = () => {
        setActiveTabButton(2)
    }

    const handleAcitveTabButtons = () => {
        setActiveTabButton(3)
    }


    const handleClickNexBasicInfoTab = () => {
        setActiveTabButton(2)
    }

    const handleClickContentTab = () => {
        setActiveTabButton(3)
    }
    const handleAcitveTabPreview = () => {
        setActiveTabButton(4)
    }

    // const [selectedCategory, setSelectedCategory] = useState('Marketing');

    // const categories = [
    //     { value: 'Marketing', label: 'Marketing' },
    //     { value: '1', label: 'One' },
    //     { value: '2', label: 'Two' },
    //     { value: '3', label: 'Three' }
    // ];

    // const handleSelect = (eventKey) => {
    //     const selected = categories.find(category => category.value === eventKey);
    //     setSelectedCategory(selected.label);
    // };

    // const handleSelect = (event) => {
    //     setSelectedCategory(event.target.value);
    // };

    const handleSelect = (value) => {
        setSelectedCategory(value);
    };

    const handleSelectTemplateType = (value) => {
        setSelectedTemplateType(value);
        setShowDropdown(false)
    };

    // const [selectBusinessType, setSelectBusinessType] = useState('Apna Research Plus')
    const initialBusinessType = BASE_URL === 'https://api.firstdemat.com/' ? 'First Demat' : 'Apna Research Plus';
    const [selectBusinessType, setSelectBusinessType] = useState(initialBusinessType);


    const handleSelectBusiness = (value) => {
        setSelectBusinessType(value);
        setShowDropdown(false)
    };

    const handleSelectLanguage = (value) => {
        setSelectedLanguage(value);
        setShowDropdown(false)
    };

    // const handleInputChange = (event) => {
    //     if (event.key === 'Enter') {
    //         event.preventDefault();
    //     } else {
    //         let value = event.target.value.toLowerCase(); // Convert to lowercase
    //         value = value.replace(/ /g, '_'); // Replace spaces with underscores
    //         value = value.replace(/[^a-z0-9_]/g, ''); // Remove special characters

    //         setTemplateName(value);
    //         setCharCount(value.length);
    //     }
    // };

    const handleInputChange = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        } else {
            let value = event.target.value.toLowerCase();

            value = value.replace(/ /g, '_');

            value = value.replace(/[^a-z0-9_]/g, '');

            // Ensure only one underscore at once
            value = value.replace(/__+/g, '_');

            setTemplateName(value);
            setCharCount(value.length);
        }
    };
    const handleMessagebodyChange = (event) => {
        setDisabledvariable(false)
        const newValue = event.target.value;

        // Set textarea height dynamically
        if (newValue === '') {
            setHeight('1px');
        } else {
            setHeight(`${event.target.scrollHeight}px`);
        }

        // Set the message body and character count
        // setTypeMessageBody(newValue);

        setTypeMessageBody(`${newValue}`);

        setCharCountBody(newValue.length);

        // Calculate the word count
        const wordCount = newValue.trim().split(/\s+/).length;

        // Find patterns like {{number}}
        const patternRegex = /{{\d+}}/g;
        let patternCount = newValue.match(patternRegex);
        const newCounter = patternCount ? patternCount.length : 0;

        const errorMessage = (
            <span>
                This template contains too many variable parameters relative to the message length. <br />
                You need to decrease the number of variable parameters or increase the message length.
            </span>
        );
        // Check word count against newCounter and show appropriate error
        if (wordCount < 4 && newCounter === 1) {
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" }, style: { marginTop: '2%' } });
            setDisabledvariable(true)
        } else if (wordCount < 7 && newCounter === 2) {
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" }, style: { marginTop: '2%' } });
            setDisabledvariable(true)
        } else if (wordCount < 10 && newCounter === 3) {
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" }, style: { marginTop: '2%' } });
            setDisabledvariable(true)
        } else if (wordCount < 13 && newCounter === 4) {
            enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 3000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: "top" }, style: { marginTop: '2%' } });
            setDisabledvariable(true)
        }

        // Handle the text fields based on pattern count
        if (newCounter > 0) {
            setTextFields([]);
            for (let i = 1; i <= newCounter; i++) {
                setTextFields((variableCounter) => [...variableCounter, '']);
            }
        } else {
            setTextFields([]);
        }

        // Set pattern and variable counters
        setPatternCounter(newCounter);
        setVariableCounter(newCounter);

    };

    const handleMessageFoooterChange = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        } else {
            const value = event.target.value;
            // Regex to allow only alphanumeric characters and spaces
            const regex = /^[a-zA-Z0-9\s]*$/;

            if (regex.test(value)) {
                setMessageFooter(value);
                setCharCountFooter(value.length);
            }
        }
    };

    const handleCharCountWebsite = (event) => {
        let input = event.target.value;
        input = input.replace(/[^a-zA-Z0-9 ]/g, '');
        input = input.replace(/^\s+/g, '');
        setWebsiteButtonName(input)
        setButtonWebsite(input);
        setCharCountWebsite(event.target.value.length)
    };

    const handleCharCountPhone = (event) => {
        let input = event.target.value;
        input = input.replace(/[^a-zA-Z0-9 ]/g, '');
        input = input.replace(/^\s+/g, '');
        setPhoneButtonName(input)
        setButtonPhone(input);
        setCharCountPhone(event.target.value.length)
    };

    const handleCharCountPhoneNumber = (event) => {
        setphonenumber(event.target.value);
        setCharCountPhoneNumber(event.target.value.length)
    };

    const handleCharCountWebsiteUrl = (event) => {
        const url = event.target.value;
        setWebsiteUrl(url);
        setCharCountWebsiteUrl(url.length)
        setIsUrlValid(validateUrl(url));

    };

    const validateUrl = (url) => {
        // const urlPattern = new RegExp('^(www\\.[a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*\\.[a-zA-Z]{2,}|[a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*\\.[a-zA-Z]{2,})$');
        // const urlPattern = new RegExp('^(www\\.[a-zA-Z\\d-]+\\.[a-zA-Z]{2,})$');
        const urlPattern = new RegExp('^www\\.([\\da-z.-]+)\\.(com|net|org|in|co\\.in|uk|live|media|tech|info|xyz)$');
        return !!urlPattern.test(url);
    };

    const handleimageclicked = () => {
        setSelectedImagePreview('')
        setSelectedImage(!selectedImage)
        setSelectedOption('image');
    }

    const handleButtonClick = (acceptType) => {
        if (fileInputRef.current) {
            fileInputRef.current.accept = acceptType;
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                toast.error('File size is too large. Max allowed limit is 5 MB.');
                return;
            } else {
                const reader = new FileReader();
                reader.onload = () => {
                    setTypeMessageImage(reader.result);
                };
                reader.readAsDataURL(file);
                setSelectedImagePreview(file.name)
                setSelectedImage(false)
            }
        }
    };



    const handleImageChange = async (e) => {


        const file = e.target.files[0];
        if (file) {
            // if (file.size > 5 * 1024 * 1024) {
            if (file.size > 1 * 1024 * 1024) {
                e.target.value = ''
                setSelectedImagePreview('')
                enqueueSnackbar('File size is too large. Max allowed limit is 1 MB.', { variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' } });
                return;
            } else {
                setLoading(true)
                setSelectedImage(URL.createObjectURL(file));

                // Get file details
                // setImageName(file.name)
                setSelectedImagePreview(file.name)
                const formData = new FormData();
                formData.append('file', file);

                let file_length = file.size;
                let file_type = file.type;
                let file_name = file.name;

                setSelectedImage(false)

                const reader = new FileReader();
                reader.onload = () => {
                    setTypeMessageImage(reader.result);
                };
                reader.readAsDataURL(file);

                // for AS3
                await Caller(uploadFile_v1, formData, POST, false, true).then(async (resData) => {
                    // console for file upload data

                    setTemplateImagePath(resData.data.media_url_path)
                    setImageId(resData.data.data.id || "");
                    // setTemplateImagePath(resData.data.media_url_path);

                });

                // get session id for file upload
                await Caller(getUploadSessionId_v1 + `?file_length=${file_length}&file_type=${file_type}&file_name=${file_name}`, " ", POST, false, true).then(async (resData) => {
                    formData.append('session_id', resData.data.id);
                    await Caller(getHandlerId_v1, formData, POST, false, true).then(async (resData1) => {
                        // setTemplateImagePath(resData1.data.templateImagePath)
                        setMediaHandlerId(resData1.data.h)
                        setLoading(false)
                        file_length = null
                        file_type = null
                        file_name = null


                    });
                });
            }
        }

    };

    const isTab4ButtonDisabled = () => {
        if (selectedImage && !selectedImagePreview) {
            return true;
        }
        if (typeMessageBody || (selectedImage && selectedImagePreview)) {
            return false;
        }
        if (typeMessageBody && selectedImage) {
            return false;
        }
        return true;
    }

    const MessagePreview = () => {
        return (

            <div style={{ width: '40%', padding: '10px', wordBreak: 'break-word' }}>
                <span>{templateName} (en)</span>
                <div style={{
                    width: '100%',
                    border: '1px solid #e0e0e0',
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    backgroundColor: '#ffffff',
                    marginTop: '10%',
                    height: '26rem',
                    overflow: 'hidden',
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                        <FaCircle style={{ marginRight: '10px', color: 'red' }} />
                        <FaCircle style={{ marginRight: '10px', color: 'orange' }} />
                        <FaCircle style={{ marginRight: '10px', color: 'green' }} />
                    </div>
                    <div style={{ backgroundColor: '#F0F2F5' }}>
                        <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#F0F2F5', padding: '10px', marginTop: '0' }}>
                            <div style={{ height: '30px', width: '30px', backgroundColor: '#000000', borderRadius: '50%', marginRight: '8px' }}></div>
                            {BASE_URL == 'https://api.firstdemat.com/' ? <span style={{ color: '#5F5F85', fontWeight: 'bold', fontSize: '0.8rem' }}>First Demat</span> : <span style={{ color: '#5F5F85', fontWeight: 'bold', fontSize: '0.8rem' }}>Apna Research Plus</span>}
                        </div>
                    </div>
                    <div style={{
                        padding: '10px',
                        backgroundImage: `url(${backgroundImage})`,
                        // borderBottom: '1px solid red',
                        height: '17.7rem',
                        position: 'relative',
                        overflowY: 'auto',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                    }}>
                        <div style={{
                            padding: '10px',
                            paddingLeft: '0',
                            paddingRight: '0',
                            backgroundColor: '#ffffff',
                            // borderRadius: '10px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            width: '80%',
                            position: 'relative',
                            margin: '10px 0',
                            paddingBottom: '0',
                            wordWrap: 'break-word',
                            // maxHeight:'95%',
                            // overflowY:'auto',
                            // scrollbarWidth:'thin',

                            borderTopRightRadius: '10px',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: '10px',
                            paddingTop: 0




                        }}>
                            {/* <div style={{
                                            position: 'absolute',
                                            top:'0',
                                            left: '-10px',
                                            // left: '10px',
                                            width: '0',
                                            height: '0',
                                            borderTop: '10px solid transparent',
                                            borderRight: '10px solid transparent',
                                            borderLeft: '10px solid #ffffff',
                                        }}></div> */}


                            {typeMessageImage && (
                                <img src={typeMessageImage} alt="Image" style={{ width: '100%', marginBottom: '5px', borderRadius: '5px', paddingTop: '0', }} />
                            )}
                            <p style={{ marginLeft: '5px', fontSize: '13px', color: '#999999', fontWeight: '500', marginRight: '5px' }}  >{typeMessageBody || 'message comes here'}</p>
                            <p style={{ marginLeft: '5px', fontSize: '12px', color: '#999999', marginRight: '5px' }} >{messageFooter || 'Footer comes here'}</p>
                            <p style={{ marginLeft: '120px', fontSize: '11px', color: '#999999', alignItems: 'end' }}>{new Date().toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true }).toUpperCase()}</p>
                            {phoneButtonName && (<div style={{ justifyContent: 'center', padding: '5px', display: 'flex', marginBottom: '10px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', color: '#00BFFF', alignItems: 'center' }}>
                                <MdLocalPhone style={{ marginRight: '5px', fontSize: '1.5rem' }} />{phoneButtonName}
                            </div>)}
                            {websitebuttonName && (<div style={{ justifyContent: 'center', display: 'flex', paddingBottom: '5px', paddingTop: '5px', borderTop: phoneButtonName ? 'none' : '1px solid #ccc', borderBottom: '1px solid #ccc', color: '#00BFFF', alignItems: 'center' }}>
                                <VscLinkExternal style={{ marginLeft: '5px', marginRight: '5px', fontSize: '1.5rem' }} />{websitebuttonName}
                            </div>)}
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        borderTop: '1px solid #e0e0e0',
                        padding: '10px',
                        backgroundColor: 'rgb(240, 240, 240)',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',

                    }}>
                        <div style={{ marginRight: '10px' }}><LuLaugh /></div>
                        <div style={{ marginRight: '10px' }}><GoPlus /></div>
                        <div style={{
                            backgroundColor: 'white', color: '#999999', fontSize: '15px',
                            marginRight: '10px', borderRadius: '5px', padding: '2px', fontSize: '12px'
                        }}> Type a message</div>
                        <div style={{ marginLeft: 'auto', alignItems: 'flex-end' }}><MdMic /></div>
                    </div>
                </div>


            </div>)
    }

    const handleSendTemplate = async () => {

        setLoading(true)

        const formData = new FormData();

        formData.append("id", "");
        formData.append("category", 'Marketing');
        formData.append("name", templateName);
        formData.append("sub_category", 'ORDER_DETAILS');
        formData.append("type", 'BODY');
        formData.append("text", typeMessageBody);
        formData.append("language_code", 'en_US');
        formData.append("footer", messageFooter.trim());
        formData.append("btn_name", phoneButtonName);
        formData.append("calling_number", phoneNumber);
        // formData.append("url_link", "https://"+websiteUrl);
        // formData.append("variables", '');
        formData.append("variables", textFields || '');
        formData.append("url_link", websiteUrl);
        // formData.append("template_id", '');
        formData.append("url_btn_name", websitebuttonName);

        formData.append("media_handler_id", mediaHandlerId || "");
        formData.append("image_name", imageName || "");
        formData.append("image_id", imageId || "");
        formData.append("template_id", "");

        const normalizedPath = templateImagePath?.replace(/\\/g, '/');
        const pathParts = normalizedPath?.split('public/');

        let relativePath;
        if (pathParts) {
            relativePath = pathParts.length > 1 ? 'public/' + pathParts[1] : '';
        }

        formData.append("image_path", templateImagePath ? templateImagePath : '')

        // Convert FormData to an object for easier logging
        const formDataObject = {};
        formData.forEach((value, key) => {
            formDataObject[key] = value;
        });

        const response = await Caller(createTemplate_v1, formData, POST, false, true).then((response) => {

            if (response.success === true) {

                const newTemplate = {
                    id: response.data.id,
                    template_name: templateName,
                    body: typeMessageBody,
                    media_handler_id: mediaHandlerId || "",
                    image_name: imageName || "",
                    image_id: imageId || "",
                    image_path: templateImagePath ? templateImagePath : '',
                    footer: messageFooter,
                    btn_name: phoneButtonName,
                    calling_number: phoneNumber,
                    variable_value: '',
                    template_type_id: response.data.template_type_id,
                    template_id: response.data.template_id,
                    status: response.data.status,
                    url_link: websiteUrl,
                    url_btn_name: websitebuttonName,
                    is_deleted: 0,
                    image_url: relativePath ? `${BASE_URL}${relativePath}` : '' // construct full image URL
                    // image_url: templateImagePath  // construct full image URL

                };

                setSubmitClicked(true)
                enqueueSnackbar('Template Created successfully.', {
                    variant: 'success', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' }, onClose: (event, reason) => {
                        if (reason === 'timeout') {
                            onClose();
                        }
                    }
                });

                setMediaHandlerId('')
                setImageId('')
                setImageName('')
                setTemplateImagePath(null) // image path reset
                setTemplateName('')
                setTypeMessageBody('')
                onAddTemplate(newTemplate);
                // onClose();
                setLoading(false)
            } else {
                setMediaHandlerId('')
                setImageId('')
                setImageName('')
                setTemplateImagePath(null) // image path reset
                setTemplateName('')
                setTypeMessageBody('')
                enqueueSnackbar('Template creation failed!', {
                    variant: 'error', autoHideDuration: 2000, TransitionProps: { direction: "left" }, anchorOrigin: { horizontal: "right", vertical: 'top' }, onClose: (event, reason) => {
                        if (reason === 'timeout') {
                            onClose();
                        }
                    }
                });
            }
            // onClose();
        })

    }

    const handleButtonDisabled = () => {
        // if (showWebsiteFields && (!buttonWebsite || !websiteUrl)) {
        if (showWebsiteFields && (!buttonWebsite || !isUrlValid)) {
            return true;
        }
        // if (showPhoneFields && (!buttonPhone || !phoneNumber)) {
        if (showPhoneFields && (!buttonPhone || !(phoneNumber.length >= 10 && /^[6-9]/.test(phoneNumber)))) {
            return true;
        }
        return false;
    }

    const handleTextFieldChange = (index) => (event) => {
        const newTextFields = [...textFields];
        // newTextFields[index] = event.target.value;
        newTextFields[index] = event.target.value.replace(/\s+/g, '');
        setTextFields(newTextFields);
        // if (props.setVariableValue) {
        //     props.setVariableValue(newTextFields);
        // }
    };


    const isDisabled = () => {
        if (textFields.some(item => item === '')) {
            return true
        } else {
            return false
        }
    }


    return (
        <Container fluid>
            <Row  >
                {/* <Col xs={1} style={{ border: '1px solid #E5E4E2', height: 'auto', width: '50px' }}>
                    <MiniSidebar />
                </Col> */}

                <Col style={{ padding: 0, }}>
                    <div style={{ height: '40px', width: '100%', padding: 0, borderBottom: '1px solid #E5E4E2', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 20, fontsize: '1rem' }}>
                        <FaArrowLeft style={{ cursor: 'pointer' }} onClick={onClose} />
                        <h5>New Templates</h5>
                    </div>

                    <div style={{ height: '50px', width: '100%', padding: 0, borderBottom: '1px solid #E5E4E2', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 20, width: '100%', borderTop: 'none' }}>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 100, }}>
                            <div style={{ width: '30%', height: '60px', display: 'flex', justifyContent: 'center', borderBottom: activeTabButton === 1 ? '3px solid green' : 'none' }} >
                                <Button variant="link" style={{ textDecoration: 'none', padding: 0, marginBottom: 0, color: "black" }} onClick={handleAcitveTabBasicInfo} > <Badge bg={activeTabButton === 1 ? "success" : 'secondary'} style={{ marginLeft: '10px', borderRadius: '50%' }}>1</Badge> Basic Info</Button>
                            </div>

                            <div style={{ width: '30%', height: '60px', display: 'flex', justifyContent: 'center', borderBottom: activeTabButton === 2 ? '3px solid green' : 'none' }} >
                                <Button variant="link" style={{ textDecoration: 'none', padding: 0, marginBottom: 0, color: "black" }} disabled={!templateName} onClick={handleAcitveTabContent} > <Badge bg={activeTabButton === 2 ? "success" : 'secondary'} style={{ marginLeft: '10px', borderRadius: '50%' }}>2</Badge> Content</Button>
                            </div>

                            <div style={{ width: '30%', height: '60px', display: 'flex', justifyContent: 'center', borderBottom: activeTabButton === 3 ? '3px solid green' : 'none' }} >
                                <Button variant="link" style={{ textDecoration: 'none', padding: 0, marginBottom: 0, color: "black" }} disabled={!(templateName && !isTab4ButtonDisabled()) || isDisabled()} onClick={handleAcitveTabButtons} > <Badge bg={activeTabButton === 3 ? "success" : 'secondary'} style={{ marginLeft: '10px', borderRadius: '50%' }}>3</Badge> Buttons</Button>
                            </div>
                            <div style={{ width: '30%', height: '60px', display: 'flex', justifyContent: 'center', borderBottom: activeTabButton === 4 ? '3px solid green' : 'none' }} >
                                <Button variant="link" style={{ textDecoration: 'none', padding: 0, marginBottom: 0, color: "black" }} disabled={!(activeTabButton === 3 && !handleButtonDisabled())} onClick={handleAcitveTabPreview} > <Badge bg={activeTabButton === 4 ? "success" : 'secondary'} style={{ marginLeft: '10px', borderRadius: '50%' }}>4</Badge> Preview</Button>
                            </div>


                        </div>
                        {/* <div style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '5%' }}>Message Preview</div> */}

                    </div>
                    {activeTabButton === 1 && <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '100%', padding: 10, borderTop: 'none', width: '100%', }}>

                            <div style={{ width: '100%', padding: 1, border: 'none' }}>

                                <div style={{ width: '100%', padding: "5px", border: 'none' }}>
                                    <Form>
                                        <div style={{ marginLeft: '1px' }}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label style={{ fontSize: '11px' }}>Template name</Form.Label>
                                                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', wordWrap: 'break-word', fontSize: '12px' }}>

                                                    {/* <Form.Control type="text" placeholder='Enter Template name' maxLength={512} style={{ fontSize: '12px', width: '100%', padding: '3px', paddingRight: '70px' }} value={templateName} onChange={handleInputChange}  /> */}
                                                    <Form.Control type="text" placeholder='Enter Template name' maxLength={200} style={{ fontSize: '12px', width: '100%', padding: '3px', paddingRight: '70px' }} value={templateName} onChange={handleInputChange} onKeyDown={handleInputChange} />
                                                    <style>
                                                        {`
                                                        .form-control:focus {
                                                            border-color: #ccc !important;  // Ensure the border color stays the same on focus
                                                            box-shadow: none !important;  // Remove the default blue shadow on focus
                                                        }
                                                        `}
                                                    </style>
                                                    <span style={{ position: 'absolute', right: '10px', fontSize: '12px', color: '#888', marginLeft: '5px' }}>
                                                        {charCount}/200
                                                    </span></div>
                                                <Form.Text className="text-muted" style={{ fontSize: '12px' }}>
                                                    Only lower case letters(a), numbers(0) and underscore ( _ ) allowed E.g.: stock1_clearance_sale
                                                </Form.Text>
                                            </Form.Group>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column', padding: '1px', gap: 1, fontSize: '11px' }}>
                                            <div style={{ width: '100%' }}>
                                                <Form.Group className="mb-3" controlId="category">
                                                    <Form.Label style={{ fontSize: '11px' }}>Category</Form.Label>
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                backgroundColor: '#fff',
                                                                color: 'black',
                                                                border: '1px solid #E5E4E2',
                                                                padding: '3px',
                                                                fontSize: '14px'
                                                            }}
                                                        >
                                                            <span>{selectedCategory}</span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{ width: '100%', padding: '10px' }}>
                                                            <div
                                                                style={{
                                                                    marginBottom: '10px',
                                                                    backgroundColor: selectedCategory === 'Marketing' ? '#E5E4E2' : 'transparent',
                                                                    padding: '10px',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '4px',
                                                                    fontSize: '14px'

                                                                }}
                                                                onClick={() => handleSelect('Marketing')}
                                                            >
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="marketing"
                                                                    label="Marketing"
                                                                    name="category"
                                                                    value="Marketing"
                                                                    // checked={selectedCategory === 'Marketing'}
                                                                    checked={selectedCategory}
                                                                    onChange={() => handleSelect('Marketing')}
                                                                    style={{ pointerEvents: 'none', fontSize: '14px' }} // Disable individual interaction to avoid redundancy
                                                                />
                                                                <div style={{ marginLeft: '24px' }}>
                                                                    <span style={{ fontSize: '12px', color: '#6c757d' }}>
                                                                        Send promotions or information about your product, service or business
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {/* <div
                                                                style={{
                                                                    backgroundColor: selectedCategory === 'Utility' ? '#E5E4E2' : 'transparent',
                                                                    padding: '10px',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '4px'
                                                                }}
                                                                onClick={() => handleSelect('Utility')}
                                                            >
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="utility"
                                                                    label="Utility"
                                                                    name="category"
                                                                    value="Utility"
                                                                    checked={selectedCategory === 'Utility'}
                                                                    onChange={() => handleSelect('Utility')}
                                                                    style={{ pointerEvents: 'none', fontSize: '14px' }} // Disable individual interaction to avoid redundancy
                                                                />
                                                                <div style={{ marginLeft: '24px' }}>
                                                                    <span style={{ fontSize: '12px', color: '#6c757d' }}>
                                                                        Send messages about an existing order or account
                                                                    </span>
                                                                </div>
                                                            </div> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Form.Group>
                                                <style>{`
                                                  input[type="radio"]:checked + label::before {
                                                  background-color: green !important;
                                                  border-color: green !important;
                                                  }
                                                 .form-check-input:checked {
                                                  background-color: green !important;
                                                  border-color: green !important;
                                                  }
                                                 .form-check-input:focus {
                                                  box-shadow: none !important;
                                                  }
                                                `}</style>

                                                <Form.Group className="mb-3" controlId="language">
                                                    <Form.Label style={{ fontSize: '11px' }}>Language</Form.Label>
                                                    <Dropdown >
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                backgroundColor: '#fff',
                                                                color: 'black',
                                                                border: '1px solid #E5E4E2',
                                                                padding: '2px',
                                                                fontsize: '12px'
                                                            }}
                                                        >
                                                            <span style={{ fontsize: '12px' }}>{selectedLanguage}</span>
                                                        </Dropdown.Toggle >
                                                        <Dropdown.Menu style={{ width: '100%', padding: '5px', fontsize: '12px' }}>
                                                            <div
                                                                style={{
                                                                    // marginBottom: '10px',
                                                                    backgroundColor: selectedLanguage === 'English' ? '#E5E4E2' : 'transparent',
                                                                    padding: '5px',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '4px',

                                                                }}
                                                                onClick={() => handleSelectLanguage('English')}
                                                            >
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="English"
                                                                    label="English"
                                                                    name="language"
                                                                    value="English"
                                                                    checked={selectedLanguage === 'English'}
                                                                    onChange={() => handleSelectLanguage('English')}
                                                                    style={{ pointerEvents: 'none', }} // Disable individual interaction to avoid redundancy
                                                                />
                                                            </div>
                                                            {/* <div
                                                                style={{
                                                                    backgroundColor: selectedLanguage === 'Hindi' ? '#E5E4E2' : 'transparent',
                                                                    padding: '10px',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '4px'
                                                                }}
                                                                onClick={() => handleSelectLanguage('Hindi')}
                                                            >
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="Hindi"
                                                                    label="Hindi"
                                                                    name="language"
                                                                    value="Hindi"
                                                                    checked={selectedLanguage === 'Hindi'}
                                                                    onChange={() => handleSelectLanguage('Hindi')}
                                                                    style={{ pointerEvents: 'none' }}
                                                                />
                                                            </div> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Form.Group>
                                                <style>{`
                                                  input[type="radio"]:checked + label::before {
                                                  background-color: green !important;
                                                  border-color: green !important;
                                                  }
                                                 .form-check-input:checked {
                                                  background-color: green !important;
                                                  border-color: green !important;
                                                  }
                                                 .form-check-input:focus {
                                                  box-shadow: none !important;
                                                  }
                                                `}</style>

                                            </div>

                                            <div style={{ width: '100%' }}>

                                                <Form.Group className="mb-3" controlId="templateType">
                                                    <Form.Label style={{ fontSize: '11px' }}>Template type</Form.Label>
                                                    <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                backgroundColor: '#fff',
                                                                color: 'black',
                                                                border: '1px solid #E5E4E2',
                                                                padding: '2px'
                                                            }}
                                                        >
                                                            <span>{selectedTemplateType}</span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{ width: '100%', padding: '10px' }}>
                                                            <div
                                                                style={{
                                                                    // marginBottom: '10px',
                                                                    backgroundColor: selectedTemplateType === 'Custom' ? '#E5E4E2' : 'transparent',
                                                                    padding: '10px',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '4px'
                                                                }}
                                                                onClick={() => handleSelectTemplateType('Custom')}
                                                            >
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="Custom"
                                                                    label="Custom"
                                                                    name="templateType"
                                                                    value="Custom"
                                                                    checked={selectedTemplateType === 'Custom'}
                                                                    onChange={() => handleSelectTemplateType('Custom')}
                                                                    style={{ pointerEvents: 'none' }} // Disable individual interaction to avoid redundancy
                                                                />
                                                            </div>
                                                            {/* <div
                                                                style={{
                                                                    backgroundColor: selectedTemplateType === 'Carousel' ? '#E5E4E2' : 'transparent',
                                                                    padding: '10px',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '4px'
                                                                }}
                                                                onClick={() => handleSelectTemplateType('Carousel')}
                                                            >
                                                                <Form.Check
                                                                    type="radio"
                                                                    id="Carousel"
                                                                    label="Carousel"
                                                                    name="templateType"
                                                                    value="Carousel"
                                                                    checked={selectedTemplateType === 'Carousel'}
                                                                    onChange={() => handleSelectTemplateType('Carousel')}
                                                                    style={{ pointerEvents: 'none' }}
                                                                />
                                                            </div> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Form.Group>
                                                <style>{`
                                                  input[type="radio"]:checked + label::before {
                                                  background-color: green !important;
                                                  border-color: green !important;
                                                  }
                                                 .form-check-input:checked {
                                                  background-color: green !important;
                                                  border-color: green !important;
                                                  }
                                                 .form-check-input:focus {
                                                  box-shadow: none !important;
                                                  }
                                                `}</style>

                                                {/* <Form.Group className="mb-3" controlId="business">
                                                    <Form.Label>Select business</Form.Label>
                                                    <Form.Select aria-label="business">
                                                        <option>Apna Research Plus</option>
                                                        <option value="1">First Demat</option>
                                                    </Form.Select>
                                                </Form.Group> */}

                                                <Form.Group className="mb-3" controlId="business">
                                                    <Form.Label style={{ fontSize: '11px' }}>Select business</Form.Label>
                                                    <Dropdown >
                                                        <Dropdown.Toggle
                                                            id="dropdown-basic"
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                backgroundColor: '#fff',
                                                                color: 'black',
                                                                border: '1px solid #E5E4E2',
                                                                padding: '2px'
                                                            }}
                                                        >
                                                            <span>{selectBusinessType}</span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{ width: '100%', padding: '10px' }}>
                                                            {/* <div
                                                                style={{
                                                                    marginBottom: '10px',
                                                                    backgroundColor: selectBusinessType == 'Apna Research Plus' ? '#E5E4E2' : 'transparent',
                                                                    padding: '10px',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '4px'
                                                                }}
                                                                onClick={() => handleSelectBusiness('Apna Research Plus')}
                                                            >
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="Apna Research Plus"
                                                                    label="Apna Research Plus"
                                                                    name="business"
                                                                    value="Apna Research Plus"
                                                                    checked={selectBusinessType === 'Apna Research Plus'}
                                                                    onChange={() => handleSelectBusiness('Apna Research Plus')}
                                                                    style={{ pointerEvents: 'none' }}
                                                                />
                                                            </div> */}
                                                            <div
                                                                style={{
                                                                    backgroundColor: selectBusinessType == 'First Demat' || 'Apna Reasearch Plus' ? '#E5E4E2' : 'transparent',
                                                                    padding: '10px',
                                                                    cursor: 'pointer',
                                                                    borderRadius: '4px'
                                                                }}
                                                                // onClick={() => handleSelectBusiness('First Demat')}
                                                                onClick={() => { BASE_URL === 'https://api.firstdemat.com/' ? handleSelectBusiness('First Demat') : handleSelectBusiness('Apna Research Plus') }}

                                                            >
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={BASE_URL === 'https://api.firstdemat.com/' ? "First Demat" : 'Apna Research Plus'}
                                                                    label={BASE_URL === 'https://api.firstdemat.com/' ? "First Demat" : 'Apna Research Plus'}
                                                                    name="business"
                                                                    value={BASE_URL === 'https://api.firstdemat.com/' ? "First Demat" : 'Apna Research Plus'}
                                                                    checked={BASE_URL === 'https://api.firstdemat.com/' ? selectBusinessType === 'First Demat' : selectBusinessType === 'Apna Research Plus'}
                                                                    // onChange={() => handleSelectBusiness('First Demat')}
                                                                    onChange={() => { BASE_URL === 'https://api.firstdemat.com/' ? handleSelectBusiness('First Demat') : handleSelectBusiness('Apna Research Plus') }}
                                                                    style={{ pointerEvents: 'none' }}
                                                                />
                                                            </div>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Form.Group>
                                                <style>{`
                                                  input[type="radio"]:checked + label::before {
                                                  background-color: green !important;
                                                  border-color: green !important;
                                                  }
                                                 .form-check-input:checked {
                                                  background-color: green !important;
                                                  border-color: green !important;
                                                  }
                                                 .form-check-input:focus {
                                                  box-shadow: none !important;
                                                  }
                                                `}</style>
                                            </div>
                                        </div>
                                    </Form>

                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    <Button style={{ backgroundColor: templateName ? '#3F906D' : '#E5E4E2', border: 'none' }} disabled={templateName ? false : true} onClick={handleClickNexBasicInfoTab}>
                                        Next <FaArrowRight style={{ marginLeft: '8px', marginBottom: '3px' }} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ width: '18%', padding: '10px', borderRight: '1px solid #E5E4E2', wordBreak: 'break-word' }}>
                            <spna>{templateName} (en)</spna>
                        </div> */}
                        {/* <MessagePreview /> */}
                    </div>
                    }

                    {activeTabButton === 2 && <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {loading && <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
                            zIndex: 20, // higher than other elements
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <HashLoader color="#176433" size={100} />
                        </div>}
                        <div style={{ width: '80%', padding: 0, borderTop: 'none', width: '100%', height: 'auto' }}>

                            <div style={{ width: '100%', padding: 0, border: 'none' }}>

                                <div style={{ padding: '10px' }}>
                                    <h6 style={{ color: "#8a8988", fontWeight: '500' }}>Header (Optional)</h6>
                                    {/* <p style={{ color: '#b0afac' }}>Add a title for your message. Your title can't include more than one variable.</p> */}
                                    <div style={{ display: 'flex', gap: '20px', marginTop: '25px' }}>
                                        <button style={{ backgroundColor: selectedOption === 'None' ? '#3F906D' : 'white', border: '1px solid #3F906D', borderRadius: '25px', color: selectedOption === 'None' ? '#fff' : '#3F906D' }} onClick={() => { setSelectedImage(false); setSelectedImagePreview(''); setTypeMessageImage(''); setSelectedOption('None'); }}>
                                            None
                                        </button>
                                        {/* <button style={{ backgroundColor: 'none', border: '1px solid #3F906D ', borderRadius: '25px', color: '#3F906D ' }}>
                                            Text
                                        </button> */}
                                        {/* <button style={{ backgroundColor: selectedOption === 'image' ? '#3F906D' : 'white',border: '1px solid #3F906D ', borderRadius: '25px', color:selectedOption === 'image' ? '#fff' : '#3F906D' }} onClick={handleimageclicked}>
                                            Image
                                        </button> */}

                                        {/* <button style={{ backgroundColor: selectedOption === 'image' ? '#3F906D' : 'white', border: '1px solid #3F906D ', borderRadius: '25px', color: selectedOption === 'image' ? '#fff' : '#3F906D' }} onClick={handleImageChange}>
                                            Image
                                        </button> */}
                                        <div>
                                            <button style={{ backgroundColor: selectedOption === 'image' ? '#3F906D' : 'white', border: '1px solid #3F906D ', borderRadius: '25px', color: selectedOption === 'image' ? '#fff' : '#3F906D' }} onClick={handleimageclicked}>
                                                Image
                                            </button>
                                        </div>
                                        {/* <button style={{ backgroundColor: 'none', border: '1px solid #3F906D ', borderRadius: '25px', color: '#3F906D ' }}>
                                            Video
                                        </button>
                                        <button style={{ backgroundColor: 'none', border: '1px solid #3F906D ', borderRadius: '25px', color: '#3F906D ' }}>
                                            Document
                                        </button> */}
                                        {/* <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        /> */}
                                    </div>
                                    {selectedImagePreview && <p style={{ fontSize: '12px', fontWeight: '500', color: "#8a8988", marginTop: '10px' }}>{selectedImagePreview} <MdCancel style={{ fontSize: '15px', Color: ' rgb(108 117 125)' }} onClick={() => { setSelectedImagePreview(''); setTypeMessageImage(''); setSelectedImage(true) }} />
                                    </p>}
                                    {selectedImage && (<div style={{ marginTop: '10px', }}>
                                        <p style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: '2px', color: "#8a8988" }}>Image type allowed: JPEG, PNG</p>
                                        <p style={{ fontSize: '12px', fontWeight: 'bold', color: "#8a8988" }}>File size limit: 1 MB</p>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <button
                                                style={{
                                                    padding: '10px',
                                                    backgroundColor: '#3F906D',
                                                    color: '#fff',
                                                    border: 'none',
                                                    borderRadius: '5px'
                                                }}
                                                // onClick={() => fileInputRef.current.click()}
                                                // onClick={() => handleButtonClick(['image/jpeg', 'image/png'])}
                                                onClick={() => handleButtonClick(['image/jpeg', 'image/png'], handleImageChange)}
                                            >
                                                Upload a file
                                            </button>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={handleImageChange}
                                            />
                                            {/* <p style={{ margin:'10px' }} >or</p>
                                <a href="#">Enter URL</a> */}
                                        </div>
                                    </div>)}
                                    <div style={{ color: "#8a8988", fontWeight: '500', marginTop: '25px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <h6>Body</h6>
                                            <AiOutlineQuestionCircle style={{ marginLeft: '15px' }} />
                                        </div>
                                        <div>
                                            <p style={{ color: '#b0afac', fontSize: '15px', marginTop: '5px' }}>To add a custom variable, please add a variable in double curly brackets without a space</p>
                                        </div>
                                    </div>
                                    <div>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="typeMessageBody">
                                                <Form.Label style={{ fontSize: '18px', color: '#c2c2c2' }}>Type message body</Form.Label>

                                                <Form.Control as="textarea" rows={2} className="form-control-lg" style={{
                                                    border: 'none', fontSize: '16px', borderBottom: '2px solid #ccc', borderRadius: 0, boxShadow: 'none', height: height, overflow: 'hidden', resize: 'none', whiteSpace: 'pre-wrap', wordWrap: 'break-word'
                                                }}
                                                    value={typeMessageBody}
                                                    onChange={handleMessagebodyChange}
                                                    maxLength={500} />
                                                <span style={{ left: '90%', position: 'absolute', fontSize: '12px', color: '#888' }}>
                                                    {charCountBody}/500
                                                </span>
                                            </Form.Group>

                                            {/* <Box display="flex">
                                                {textFields && textFields.map((value, index) => (
                                                    <TextField
                                                        key={index}
                                                        label={`Variable ${index + 1}`}
                                                        variant="outlined"
                                                        value={value}
                                                        onChange={handleTextFieldChange(index)}
                                                        style={{ marginRight: '10px', marginTop: '10px' }}
                                                        required
                                                    />
                                                ))}
                                            </Box> */}

                                            <div style={{ display: 'flex', width: '700px', flexWrap: 'wrap' }}>
                                                {textFields && textFields.map((value, index) => (

                                                    <Form.Group className="mb-3" controlId="typeMessageBody">
                                                        {/* <h6 style={{ fontSize: '17px', color: '#8a8988', fontWeight: '500' }}>Footer (Optional)</h6> */}
                                                        <Form.Control type="text" className="form-control-lg" style={{
                                                            fontSize: '16px', boxShadow: 'none', width: '195px', margin: '5px'
                                                        }}
                                                            value={value}
                                                            onChange={handleTextFieldChange(index)}
                                                            placeholder={`Variable ${index + 1}`}
                                                            required
                                                        />
                                                    </Form.Group>
                                                ))}
                                            </div>

                                            <Form.Group className="mb-3" controlId="typeMessageBody">
                                                <h6 style={{ fontSize: '17px', color: '#8a8988', fontWeight: '500' }}>Footer (Optional)</h6>
                                                <Form.Control type="text" className="form-control-lg" style={{
                                                    border: 'none', fontSize: '16px', borderBottom: '2px solid #ccc', borderRadius: 0, boxShadow: 'none'
                                                }} value={messageFooter} maxLength={60} onChange={handleMessageFoooterChange} onKeyDown={handleMessageFoooterChange}
                                                    placeholder="Type message footer" autoComplete="off" />
                                                <span style={{ left: '90%', position: 'absolute', fontSize: '12px', color: '#888' }}>
                                                    {charCountFooter}/60
                                                </span>

                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>

                                <div style={{ textAlign: 'right' }}>
                                    <Button style={{ backgroundColor: !isTab4ButtonDisabled() ? '#3F906D' : '#E5E4E2', border: 'none' }} disabled={isTab4ButtonDisabled() || isDisabled()} onClick={handleClickContentTab}>
                                        Next <FaArrowRight style={{ marginLeft: '8px', marginBottom: '3px' }} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* <MessagePreview /> */}

                    </div>
                    }

                    {/*  <---Button Step three--> */}

                    {activeTabButton === 3 && <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '80%', padding: 8, borderTop: 'none', width: '100%', height: 'auto' }}>

                            <div style={{ width: '100%', border: 'none' }}>

                                <div style={{ margin: '0 auto' }}>
                                    <h6 style={{ color: "#3d3d3d", fontSize: '15px' }}>Create buttons that let customers respond to your message or take action</h6>
                                    <div style={{ display: 'flex', gap: '20px', marginTop: '25px' }}>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                id="dropdown-basic"
                                                style={{
                                                    backgroundColor: 'transparent',
                                                    border: '1px dotted #3F906D',
                                                    color: 'black',
                                                    fontWeight: 'bold',
                                                    fontSize: '15px'
                                                }}
                                            >
                                                + Add a button
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu style={{ border: 'none', width: '250px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', }}>
                                                {/* <Dropdown.Header>QUICK REPLY</Dropdown.Header>
                                                <Dropdown.Item href="#/action-1">Marketing opt-out <span style={{ float: 'left', color: 'green' }}>Recommended</span></Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Custom button</Dropdown.Item>

                                                <Dropdown.Divider /> */}

                                                <Dropdown.Header>CALL TO ACTION</Dropdown.Header>
                                                <Dropdown.Item onClick={handleWebsiteClick}>Visit website
                                                    {/* <span style={{ float: 'left', color: 'grey' }}>2 buttons maximum</span> */}
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={handlePhoneClick}>Call phone number
                                                    {/* <span style={{ float: 'left', color: 'grey' }}>1 button maximum</span> */}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                    {/* <div style={{ color: "#8a8988", fontWeight: '500', marginTop: '20px', display: 'flex', flexDirection: 'row' }}>
                                        <AiOutlineExclamationCircle style={{ marginTop: '3px', fontSize: '20px', }} />
                                        <p style={{ color: '#7a7a7a', fontSize: '15px', paddingLeft: "10px" }}>if you add more than 3 buttons they will appear in a list</p>
                                    </div> */}
                                    {!(showWebsiteFields || showPhoneFields) &&
                                        <div style={{ marginTop: '1rem' }}>
                                            <span style={{ fontSize: '18px' }}> No buttons</span>
                                        </div>
                                    }
                                </div>

                                {(showPhoneFields || showWebsiteFields) && (
                                    <div style={{ backgroundColor: '#f5f6f7', padding: '10px', borderRadius: '10px', width: '100%', maxWidth: '95%', margin: '0 auto', marginTop: '20px' }}>
                                        <h6 style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                            {/* <HiOutlineArrowsUpDown style={{ marginRight: '1rem' }} /> */}
                                            Call to action
                                        </h6>

                                        {showWebsiteFields && (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                                {/* <PiDotsSixVerticalBold /> */}

                                                <div style={{ alignItems: 'center', width: '90%', backgroundColor: '#ffffff', padding: '10px', borderRadius: '10px', border: '1px solid #e0e0e0' }}>
                                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', gap: '10px', marginBottom: '10px' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <label style={{ fontSize: '11px', marginBottom: '5px' }}>Type of action</label>
                                                            <select style={{ fontSize: '13px', width: '100%', padding: '4px', borderRadius: '4px', border: '1px solid #e0e0e0' }}>
                                                                <option>Visit website</option>
                                                            </select>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <label style={{ fontSize: '11px', marginBottom: '5px' }}>Button name</label>
                                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', wordWrap: 'break-word' }}>

                                                                <input type="text" placeholder="Button name" style={{ fontSize: '13px', width: '100%', padding: '4px', borderRadius: '4px', border: '1px solid #e0e0e0', paddingRight: '50px' }} value={buttonWebsite} onChange={handleCharCountWebsite} maxLength={25} />
                                                                <span style={{ position: 'absolute', right: '10px', fontSize: '12px', color: '#888', marginLeft: '5px', pointerEvents: 'none' }}>
                                                                    {charCountWebsite}/25
                                                                </span></div>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', gap: '10px', marginBottom: '20px' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <label style={{ fontSize: '11px', marginBottom: '5px' }}>URL type</label>
                                                            <select style={{ fontSize: '13px', width: '100%', padding: '4px', borderRadius: '4px', border: '1px solid #e0e0e0' }}>
                                                                <option>https://</option>
                                                            </select>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                                            <label style={{ fontSize: '11px', marginBottom: '5px' }}>Website URL</label>
                                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', wordWrap: 'break-word' }}>

                                                                <input type="text" placeholder="Example: www.example.com" style={{ fontSize: '13px', width: '100%', padding: '4px', borderRadius: '4px', border: '1px solid #e0e0e0', paddingRight: '70px' }} value={websiteUrl} onChange={handleCharCountWebsiteUrl} maxLength={500} />
                                                                <span style={{ position: 'absolute', right: '2%', fontSize: '12px', color: '#888', marginLeft: '5px', pointerEvents: 'none' }}>
                                                                    {charCountWebsiteUrl}/500

                                                                </span></div>
                                                            {/* <IoInformationCircleOutline style={{ alignItems: 'center', position: 'absolute', right: '7px', top: '70%', transform: 'translateY(-50%)', color: '#888888', fontSize: '20px' }} /> */}
                                                        </div>
                                                    </div>
                                                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                                            <input type="checkbox" id="analytics" />
                                                            <label htmlFor="analytics" style={{ fontSize: '13px', marginLeft: '5px' }}>Enable analytics for your link</label>
                                                            <IoInformationCircleOutline style={{ alignItems: 'center', position: 'absolute', right: '-30px', top: '60%', transform: 'translateY(-50%)', color: '#888888', fontSize: '20px' }} />
                                                        </div>
                                                        <button style={{ fontSize: '13px', backgroundColor: 'transparent', border: '1px solid #e0e0e0', padding: '5px 10px', borderRadius: '5px', cursor: 'pointer' }}>
                                                            + Add variable
                                                        </button>
                                                    </div> */}
                                                </div> <button onClick={handleCloseWebsite} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                                    <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#888888' }}>×</span>
                                                </button>
                                            </div>)}

                                        {/* for phone */}
                                        {showPhoneFields && (

                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                                {/* <PiDotsSixVerticalBold /> */}

                                                <div style={{ alignItems: 'center', width: '90%', backgroundColor: '#ffffff', padding: '10px', borderRadius: '10px', border: '1px solid #e0e0e0' }}>
                                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', gap: '10px', marginBottom: '10px' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <label style={{ fontSize: '11px', marginBottom: '5px' }}>Type of action</label>
                                                            <select style={{ fontSize: '13px', width: '100%', padding: '4px', borderRadius: '4px', border: '1px solid #e0e0e0' }}>
                                                                <option>Call Phone Number</option>
                                                            </select>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <label style={{ fontSize: '11px', marginBottom: '5px' }}>Button name</label>
                                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', wordWrap: 'break-word' }}>
                                                                <input type="text" placeholder="Button name" style={{ fontSize: '13px', width: '100%', padding: '4px', borderRadius: '4px', border: '1px solid #e0e0e0', paddingRight: '50px' }} value={buttonPhone} onChange={handleCharCountPhone} maxLength={25} />
                                                                <span style={{ position: 'absolute', right: '10px', fontSize: '12px', color: '#888', marginLeft: '5px', pointerEvents: 'none' }}>
                                                                    {charCountPhone}/25
                                                                </span></div>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', gap: '10px', marginBottom: '20px' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <label style={{ fontSize: '11px', marginBottom: '5px' }}>Country Code</label>
                                                            <select style={{ backgroundColor: '#f5f6f7', fontSize: '13px', width: '100%', padding: '4px', borderRadius: '4px', border: '1px solid #e0e0e0' }}>
                                                                <option> +91</option>
                                                            </select>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                                            <label style={{ fontSize: '11px', marginBottom: '5px' }}>phone number</label>
                                                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', wordWrap: 'break-word' }}>

                                                                <input type="text" style={{ fontSize: '13px', width: '100%', padding: '4px', borderRadius: '4px', border: '1px solid #e0e0e0', paddingRight: '24px' }} value={phoneNumber} onChange={handleCharCountPhoneNumber} maxLength={10} onKeyPress={(e) => {
                                                                    // Allow only numbers and the backspace key
                                                                    const isValidInput = /[0-9]/.test(e.key) || e.key === "Backspace";
                                                                    if (!isValidInput) {
                                                                        e.preventDefault();
                                                                    }
                                                                }} />
                                                                <span style={{ position: 'absolute', right: '10px', fontSize: '12px', color: '#888', marginLeft: '5px', pointerEvents: 'none' }}>
                                                                    {charCountPhoneNumber}/10
                                                                </span></div>
                                                        </div>
                                                    </div>

                                                </div> <button onClick={handleClosePhone} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                                    <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#888888' }}>×</span>
                                                </button>
                                            </div>)}
                                    </div>
                                )}

                                <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                    {/* <Button style={{ backgroundColor: '#3F906D', border: 'none', fontWeight: 'bold' }} onClick={handleClickContentTab}>
                                        Submit Template <FaArrowRight style={{ marginLeft: '8px', marginBottom: '3px' }} />
                                    </Button> */}
                                    <Button style={{ backgroundColor: handleButtonDisabled() ? '#E5E4E2' : '#3F906D', border: 'none' }} disabled={handleButtonDisabled()} onClick={handleAcitveTabPreview}>
                                        Next <FaArrowRight style={{ marginLeft: '8px', marginBottom: '3px' }} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ width: '18%', padding: '10px' }}>
                            <spna> (en)</spna>
                        </div> */}
                        {/* <MessagePreview /> */}
                    </div>
                    }
                    {activeTabButton === 4 && <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', }}>
                        {loading && <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '150%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
                            zIndex: 999999, // higher than other elements
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <HashLoader color="#176433" size={100} />
                        </div>}
                        <MessagePreview />
                        <div style={{ fontsize: '14px', padding: '20px', color: "#8a8988", fontWeight: '500' }}>
                            <p>To help Meta review your content, provide examples of the variables . Do not include any customer information
                                <br />Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of Meta's services.</p></div>
                        <div style={{ textAlign: 'right', marginTop: '10px' }}>
                            <Button style={{ backgroundColor: '#3F906D', border: 'none', fontWeight: 'bold' }} onClick={handleSendTemplate} disabled={submitClicked}>
                                Submit Template <FaArrowRight style={{ marginLeft: '8px', marginBottom: '3px' }} />
                            </Button>
                        </div>
                    </div>

                    }
                </Col>
            </Row>

            <SnackbarProvider style={{ marginTop: '15%' }} />

        </Container >
    )
}

export default NewTemplatePopup;
