
import { LOGIN_AUTH_COOKIE, LOGIN_USERTYPE_COOKIE } from './ResponseHandler';
import Cookies from 'js-cookie';


/**
 * Common header template for all requests
 */
const HEADER = {
    'Accept': 'application/json',
    'Authorization': '',
    'Content-Type': 'application/json',
    'X-API-TOKEN': '31f8ef73075910b4ec04e3ec658fa0e9',
    'x-api-version': '6.0',
    'X-USER-DEVICE-TYPE': '1',
    "Access-Control-Allow-Credentials": true,
};

/**
 * return header for the request
 * if user access token found in the localStorage
 * than append it in the header
 *
 * @param {boolean} multiPart
 *
 * @returns {json} headers
 *
 * @author Raneso<support@raneso.com>
 */
export const getHeader = async (multiPart, clientId = false) => {

    let headers = { ...HEADER };

    try {

        if (multiPart) {
            delete headers["Content-Type"];
        }
        let accessToken = false;     
        
        if(clientId){
            let adminUserType = Cookies.get(LOGIN_USERTYPE_COOKIE);
            let adminClientId = adminUserType == '1' || adminUserType == 1 ? "" : clientId;
            accessToken = await Cookies.get(LOGIN_AUTH_COOKIE+adminClientId);  
        }else{
            accessToken = await Cookies.get(LOGIN_AUTH_COOKIE);  
        }
        if (accessToken) {
            headers.Authorization = 'Bearer ' + accessToken;            
        }

    } catch (error) {
        alert('HEADERS PROBLEM => ' + JSON.stringify(error.message));
    }

    return headers;
}