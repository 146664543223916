import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, FormControl, Container, Row, Col, Dropdown } from 'react-bootstrap';
import { FaEllipsisV, FaLongArrowAltUp } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { IoMdSettings } from "react-icons/io";
import { BiSortAlt2 } from "react-icons/bi";
import { getAllBroadcasts_v1, getAllCustomers, getGroupCustomer, getAllCustomersForExport } from '../../Apis/config/Url';
import Caller from '../../Apis/config/Caller';
import { POST, GET, DELETE, PUT } from '../../Apis/config/RequestType';
import Cookies from 'js-cookie';
import * as XLSX from 'xlsx';

const Customers = () => {
    const [selectedGroup, setSelectedGroup] = useState('All Customers');
    const [searchTerm, setSearchTerm] = useState('');
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const [visibleColumns, setVisibleColumns] = useState(['name', 'mobile', 'lastMessage']);
    const [navbarHeight, setNavbarHeight] = useState(58);
    const [broadcastData, setBroadcastData] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [participantPage, setParticipantPage] = useState(1);
    const [searchTermParticipant, setSearchTermParticipant] = useState('');
    const [customerpage, setCustomerPage] = useState('');
    const [totalCustomerPages, setTotalCustomerPages] = useState('');
    const [broadcastID, setBroadcastId] = useState('');
    const [broadcastPage, setBroadcastPage] = useState('')
    const [totalBroadcastpage, setTotalBroadcastPage] = useState('')
    const scrollRef = useRef(null);



    useEffect(() => {
        const fetchbroadcast = async () => {
            try {
                const getBroadcastName = await Caller(`${getAllBroadcasts_v1}?page=1&sortColumn=whatsapp_group_name&sortType=ASC`, '', GET, false, false);
                if (getBroadcastName.success) {
                    setBroadcastData(getBroadcastName?.data?.data);
                    setBroadcastPage(getBroadcastName?.data?.page);
                    setTotalBroadcastPage(getBroadcastName?.data?.totalPages)
                }

                const allCustomers = await Caller(`${getAllCustomers}?page=1`, '', GET, false, false);
                if (allCustomers && allCustomers.data && Array.isArray(allCustomers.data.data)) {
                    const transformedCustomers = allCustomers.data.data.map(customer => ({
                        name: `${customer.first_name || ''} ${customer.last_name || ''}`.trim(),
                        mobile: customer.mobile || '',
                        // lastMessage: customer.updated_at || '',
                        product: customer.product_name || '',
                    }));

                    setCustomerData(transformedCustomers);
                    setTotalCustomerPages(allCustomers.data.totalPages)
                    setCustomerPage(allCustomers.data.page)
                }
            } catch (error) {
                console.error('Error fetching customer details:', error);
            }
        };

        fetchbroadcast();
    }, []);

    const handleAllCustomerClick = async () => {
        if (selectedGroup === 'All Customers') return;
        setSelectedGroup('All Customers')
        setCustomerPage('')
        setTotalCustomerPages('')
        setSearchTerm('')
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
        const allCustomers = await Caller(`${getAllCustomers}?page=1`, '', GET, false, false);
        if (allCustomers && allCustomers.data && Array.isArray(allCustomers.data.data)) {
            const transformedCustomers = allCustomers.data.data.map(customer => ({
                name: `${customer.first_name || ''} ${customer.last_name || ''}`.trim(),
                mobile: customer.mobile || '',
                // lastMessage: customer.updated_at || '',
                product: customer.product_name || '',
            }));

            setCustomerData(transformedCustomers);
            setTotalCustomerPages(allCustomers?.data.totalPages)
            setCustomerPage(allCustomers?.data.page)
        }
    }

    const handleCustomerScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollBottom = scrollHeight - clientHeight - scrollTop;

        if (Math.floor(scrollBottom) <= 1 && customerpage < totalCustomerPages) {

            setCustomerPage(Number(customerpage + 1))
            if (selectedGroup === "All Customers") {
                const data = await Caller(`${getAllCustomers}?page=${(customerpage) + 1}&search=${searchTerm}`, '', GET, false, false);
                if (data && data.data && Array.isArray(data.data.data)) {
                    const newCustomers = data.data.data.map(customer => ({
                        name: `${customer.first_name || ''} ${customer.last_name || ''}`.trim(),
                        mobile: customer.mobile || '',
                        product: customer.product_name ? customer.product_name : '',
                    }));

                    setCustomerData(prevCustomerData => [...prevCustomerData, ...newCustomers]);
                }
            } else {
                const getBroadcastContact = await Caller(`${getGroupCustomer}?selectedItemId=${broadcastID}&page=${(customerpage) + 1}&s=${searchTermParticipant}`, '', GET, false, false);
                if (getBroadcastContact && getBroadcastContact.data && Array.isArray(getBroadcastContact.data.data)) {
                    const newGroupCustomer = getBroadcastContact.data.data.map(customer => ({
                        name: `${customer.first_name || ''} ${customer.last_name || ''}`.trim(),
                        mobile: customer.mobile || '',
                        product: customer.product_name ? customer.product_name : '',
                    }));

                    setCustomerData(prevCustomerData => [...prevCustomerData, ...newGroupCustomer]);
                }
            }

        }
    };

    const handlebroadcastScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollBottom = scrollHeight - clientHeight - scrollTop;

        if (Math.floor(scrollBottom) <= 1 && broadcastPage < totalBroadcastpage) {
            setBroadcastPage(Number(broadcastPage + 1))
            const data = await Caller(`${getAllBroadcasts_v1}?page=${(broadcastPage) + 1}&sortColumn=whatsapp_group_name&sortType=ASC`, '', GET, false, false);
            setBroadcastData(prevbroadcastData => [...prevbroadcastData, ...data?.data?.data]);
        }
    };

    const handleSort = (column) => {
        const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(newDirection);
    };

    // const handleExport = () => {
    //     const csvContent = `data:text/csv;charset=utf-8,${['Name', 'Phone', 'Product'].join(',')}\n` +
    //         customerData.map(customer => `${customer.name},${customer.mobile},${customer.product}`).join('\n');

    //     const encodedUri = encodeURI(csvContent);
    //     const link = document.createElement('a');
    //     link.setAttribute('href', encodedUri);
    //     link.setAttribute('download', 'customer_data.csv');
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };
    const handleBroadcastClick = async (id) => {
        if (selectedGroup === broadcastData.find(b => b.id === id)?.broadcast_name) return;
        setSelectedGroup(broadcastData.find(b => b.id === id)?.broadcast_name || '');
        setCustomerPage('')
        setTotalCustomerPages('')
        setBroadcastId(id)
        setSearchTerm('')
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }

        const getBroadcastContact = await Caller(`${getGroupCustomer}?selectedItemId=${id}&page=1`, '', GET, false, false);
        if (getBroadcastContact && getBroadcastContact.data && Array.isArray(getBroadcastContact.data.data)) {
            const transformedCustomers = getBroadcastContact.data.data.map(customer => ({
                name: `${customer.first_name || ''} ${customer.last_name || ''}`.trim(),
                mobile: customer.mobile || '',
                product: customer.product_name ? customer.product_name : '',
                // lastMessage: customer.updated_at ? customer.updated_at : '',
            }));
            setCustomerData(transformedCustomers);
            setCustomerPage(getBroadcastContact?.data?.page)
            setTotalCustomerPages(getBroadcastContact?.data?.totalPages)
        }
    }

    const handleSearch = async (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (selectedGroup === 'All Customers') {
            await searchCustomers(value);
        } else {
            await searchGroupCustomers(broadcastID, value);
        }
    };

    // Search API for "All Customers"
    const searchCustomers = async (searchTerm) => {
        try {
            const response = await Caller(`${getAllCustomers}?search=${searchTerm}`, '', GET, false, false);
            if (response.success) {
                const transformedCustomers = response.data.data.map(customer => ({
                    name: `${customer.first_name || ''} ${customer.last_name || ''}`.trim(),
                    mobile: customer.mobile || '',
                    product: customer.product_name || '',
                }));
                setCustomerData(transformedCustomers);
                setTotalCustomerPages(response.data.totalPages);
                setCustomerPage(response.data.page);
            }
        } catch (error) {
            console.error('Error searching customers:', error);
        }
    };

    // Search API for Group Customers
    const searchGroupCustomers = async (broadcastID, searchTerm) => {
        try {
            const response = await Caller(`${getGroupCustomer}?selectedItemId=${broadcastID}&s=${searchTerm}`, '', GET, false, false);
            if (response.success) {
                const transformedCustomers = response.data.data.map(customer => ({
                    name: `${customer.first_name || ''} ${customer.last_name || ''}`.trim(),
                    mobile: customer.mobile || '',
                    product: customer.product_name || '',
                }));
                setCustomerData(transformedCustomers);
                setTotalCustomerPages(response.data.totalPages);
                setCustomerPage(response.data.page)
            }
        } catch (error) {
            console.error('Error searching group customers:', error);
        }
    };

    // const handleExport = async () => {
    //     try {
    //         const exportUrl = selectedGroup === 'All Customers'
    //             ? `${getAllCustomersForExport}?export=true`
    //             : `${getAllCustomersForExport}?selectedItemId=${broadcastID}&search=${searchTerm}`;

    //         const response = await Caller(exportUrl, '', GET, false, false);

    //         if (response.success) {
    //             const allCustomersData = response.data;

    //             const csvContent = `data:text/csv;charset=utf-8,${['Name', 'Phone', 'Product'].join(',')}\n` +
    //                 allCustomersData.map(customer => {
    //                     const name = `${customer.first_name || ''} ${customer.last_name || ''}`.trim();
    //                     const mobile = customer.mobile || '';
    //                     const product = customer.product_name || '';
    //                     return `${name},${mobile},${product}`;
    //                 }).join('\n');

    //             const encodedUri = encodeURI(csvContent);
    //             const link = document.createElement('a');
    //             link.setAttribute('href', encodedUri);
    //             link.setAttribute('download', 'customer_data.csv');
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         }
    //     } catch (error) {
    //         console.error('Error exporting customer data:', error);
    //     }
    // };

    const handleExport = async () => {
        try {
            const exportUrl = selectedGroup === 'All Customers'
                ? `${getAllCustomersForExport}?export=true`
                : `${getAllCustomersForExport}?selectedItemId=${broadcastID}&search=${searchTerm}`;
    
            const response = await Caller(exportUrl, '', GET, false, false);
    
            if (response.success) {
                const allCustomersData = response.data;
    
                // Format data into an array of arrays (Excel format)
                const data = [
                    ['Name', 'Phone', 'Product'], // Header row
                    ...allCustomersData.map(customer => {
                        const name = `${customer.first_name || ''} ${customer.last_name || ''}`.trim();
                        const mobile = customer.mobile || '';
                        const product = customer.product_name || '';
                        return [name, mobile, product];
                    })
                ];
    
                // Create a worksheet and workbook
                const ws = XLSX.utils.aoa_to_sheet(data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Customers');
    
                // Generate Excel file and download it
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.setAttribute('download', 'customer_data.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error('Error exporting customer data:', error);
        }
    };



    return (
        <Container fluid style={{ scrollbarWidth: 'thin', overflowY: 'hidden', height: `calc(100vh - ${navbarHeight}px)` }}>
            <Row className="d-flex" style={{ border: '1px solid #e5e4e2', marginLeft: '37px' }}>
                <Col xs={2} className="column" style={{ userSelect: 'none', borderLeft: '1px solid #e5e4e2', borderRight: '1px solid #e5e4e2', position: 'relative', height: '100vh', padding: '0' }}>
                    {/* Sidebar for customer segment */}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #e0e0e0' }}>
                        <div style={{ userSelect: 'none', fontWeight: '500', fontSize: '1rem' }}>Customer Segments</div>
                    </div>

                    {/* Group Selection (All Customers) */}
                    <div style={{ userSelect: 'none', width: '100%', borderBottom: '1px solid #e0e0e0', padding: '10px', backgroundColor: selectedGroup === 'All Customers' ? '#e6f7ff' : '', cursor: 'pointer' }} onClick={handleAllCustomerClick}>
                        All Customers
                    </div>

                    {/* Scrollable section for broadcast data */}
                    <div style={{ scrollbarWidth: 'thin', overflowY: 'auto', height: 'calc(100vh - 150px)', padding: '0' }} onScroll={handlebroadcastScroll}>
                        {broadcastData.map((broadcastname, index) => (
                            <div key={index} style={{ width: '100%', borderBottom: '1px solid #e0e0e0', padding: '10px', backgroundColor: selectedGroup === broadcastname.broadcast_name ? '#e6f7ff' : '', cursor: 'pointer' }} onClick={() => { handleBroadcastClick(broadcastname.id) }}>
                                {broadcastname.broadcast_name}
                            </div>
                        ))}
                    </div>
                </Col>



                <Col xs={10} className="column" style={{ position: 'relative', height: '100vh', padding: '0', margin: '0', }}>
                    {/* Search and Export */}
                    <Row style={{ fontWeight: 'bold', padding: '0', margin: '0' }}>
                        <div style={{ height: '3rem', borderBottom: '1px solid #e5e4e2', display: 'flex', alignItems: 'center' }}>
                            <div style={{ userSelect: 'none', flexGrow: 1, fontWeight: '500', color: '#3F906D' }}>
                                {customerData.length} customer{customerData.length > 1 ? 's' : ''}
                            </div>

                            {/* Right-side elements (Export, Search label, Search bar) */}
                            <div style={{ userSelect: 'none', marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                {Cookies.get('auth_usertype') == '1' && <div style={{ marginRight: '10px', borderRight: '1px solid #3F906D', paddingRight: '10px', color: '#3F906D', cursor: 'pointer' }} onClick={handleExport}>Export</div>}
                                {/* <div style={{ marginRight: '10px', color: '#3F906D' }}>Search</div> */}
                                <Form inline style={{ width: '100%', padding: '10px' }}>
                                    <FormControl
                                        type="text"
                                        placeholder="Search Customers"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        // onChange={(e) => setSearchTerm(e.target.value)}
                                        style={{ width: '100%', padding: '5px', boxSizing: 'border-box' }}
                                    />
                                </Form>
                            </div>
                        </div>
                    </Row>

                    {/* Customer Data Display */}
                    <div style={{ overflowY: 'auto', height: 'calc(100vh - 125px)', width: '100%', scrollbarWidth: 'thin', }} ref={scrollRef} onScroll={handleCustomerScroll}>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(150px, 1fr))', borderBottom: '1px solid #ddd', fontWeight: 'bold', padding: '10px' }}>
                            <div style={{ userSelect: 'none', fontWeight: '500' }}>
                                Name
                            </div>
                            <div style={{ userSelect: 'none', fontWeight: '500' }}>
                                Phone
                            </div>
                            <div style={{ userSelect: 'none', fontWeight: '500' }}>
                                Product
                            </div>
                        </div>

                        {customerData.map((customer, index) => (
                            <div key={index} style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(150px, 1fr))', borderBottom: '1px solid #ddd', padding: '10px',wordBreak:"break-word",   }}>
                                <div style={{maxWidth:'80%'}}>{customer.name}</div>
                                <div>{customer.mobile}</div>
                                <div>{customer.product}</div>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Customers;