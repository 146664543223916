import React from 'react'
import Container from 'react-bootstrap/Container';
import { RiCheckDoubleLine } from "react-icons/ri";
import Button from 'react-bootstrap/Button';
import { FaGraduationCap } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import MiniSidebar from '../components/MiniSidebar';
import { BASE_URL } from '../Apis/config/Url';

const Navbar = () => {
    return (
        <Container fluid style={{
            padding: 10, backgroundColor: '#f9f9f9', position: 'sticky',
            top: 0, zIndex: 9999
        }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ marginLeft: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 7 }}>
                    {/* <img src="./image/fd_logo.png" alt="logo" height={36} /> */}
                    {BASE_URL == 'https://api.firstdemat.com/' ? <img src="./image/fd_logo.png" alt="logo" height={36} /> : <img src="./image/arp_logo.png" alt="logo" width={100} height={36} />}
                </div>

                <div style={{ display: 'flex', gap: 7, marginRight: '10px' }} >
                    {/* <div >
                        <Button style={{ backgroundColor: '#fbfbfc', borderColor: '#fbfbfc', color: 'black', border: ' 0.5px solid gray', borderRadius: '5px' }}>
                            <FaGraduationCap style={{ color: 'green' }} /> DoubleTick Training
                        </Button>
                    </div>
                    <div>
                        <Button style={{ backgroundColor: '#fbfbfc', borderColor: '#fbfbfc', color: 'black', border: ' 0.5px solid gray', borderRadius: '5px' }}>
                            <IoIosHelpCircleOutline />Get Help
                        </Button>
                    </div> */}

                </div>

            </div>

            <div>
                <MiniSidebar />
            </div>
        </Container>
    )
}

export default Navbar
